import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import '../css/slides.css';


class Search extends Component {

   
    
    constructor(props) {
        super(props)
       
        this.changeCountry = this.changeCountry.bind(this)
        this.changeCity = this.changeCity.bind(this)

        this.state = {
            selectedCountry: this.props.selectedCountry,
            cityUpdated: false
            
        }
    }
    
    
    componentDidUpdate(prevProps, prevState) {

        //alert("select city length:"+this.cityElement.length)
        
        if (prevProps.selectedCountry != prevState.selectedCountry) {
            //alert("changing city from country")
            this.cityElement.addEventListener('change',this.changeCity)
            this.cityElement.dispatchEvent(new Event("change"))
                
            //alert("changing city 1")
        
            let state = {
                ...this.state,
                selectedCountry: prevProps.selectedCountry
            }
            this.setState(state)
            
        }
        
        
        if ((this.cityElement?.length > 0) && (!this.state.cityUpdated)) {
            //alert("changing city from didupdate")
            this.cityElement.addEventListener('change',this.changeCity)
            this.cityElement.dispatchEvent(new Event("change"))
        
            //alert("changing city 2")
            
            let state = {
                ...this.state,
                cityUpdated: true
            }
            this.setState(state)
        
        }
        
        
    }
    
    changeCountry(e) {
        e.preventDefault
        
        let state = {
                ...this.state,
                cityUpdated: false
        }
        //this.setState(state)
        
        this.props.changeCountry(e)
    }
    
    changeCity(e) {
        e.preventDefault
        
        let state = {
                ...this.state,
                cityUpdated: false
        }
        //this.setState(state)
        
        var searchUser = this.refs._user.value
        
        if (searchUser.length==0) {
            searchUser=null
        }
        
        this.props.changeCity(e, searchUser)
    }
    
    render() {
        const {
            searchMode,
            countryList,
            selectedCountry,
            cityList,
            selectedCity,
            searchUser
        }  = this.props 
       
        if (searchMode == "searchAndResults" || searchMode == "searchMap" || searchMode == "loading") {
        return (
                <div>
                    <div className = "flexBox" >
                        <div className = "flexCell cellLeft50  formLabel" >Country:< /div> 
                        <div className = "flexCell cellRight50" > 
                            <select id="country" onChange={e => {this.changeCountry(e)}} value={selectedCountry}>
                                {countryList.map((country, index) => (
                                    <option key={index} value={country.countryId} >{country.country}</option> 
                                 ))                                
                                }                           
                            </select>
                        < /div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "flexCell cellLeft50  formLabel" >City:< /div> 
                        <div className = "flexCell cellRight50" > 
                            <select id="city" onChange={e => {this.changeCity(e)}} value={selectedCity}
                                ref={(input) => this.cityElement = input}>
                                {cityList.map((city, index) => (
                                    <option key={index} value={city.cityId} >{city.city}</option> 
                                 ))                                
                                }                               
                            </select>
                            <input type="hidden" ref="_user" value={(searchUser==null) ? "0" : searchUser} />
                        < /div> 
                    </div>
                </div>
        )
        } else {
            return null;
        }
    }
    
}

Search.propTypes = {
    searchMode: PropTypes.string,
    countryList: PropTypes.array,
    cityList: PropTypes.array,
    selectedCountry: PropTypes.number,
    selectedCity: PropTypes.number,
    changeCountry: PropTypes.func,
    changeCity: PropTypes.func,
    searchUser: PropTypes.number
}


Search.defaultProps = {
    changeCountry: f => f,
    changeCity: f => f    
}

export default Search
    