import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'


class Conversation extends Component {



    constructor(props) {
        super(props)        
        this.addMessage = this.addMessage.bind(this)
        this.viewAdd = this.viewAdd.bind(this)
        this.showConversationById = this.showConversationById.bind(this)
        
        this.state = {
        }
        
    }
    
    viewAdd(e, advertisementId) {
        e.preventDefault()        
        this.props.viewAdd(advertisementId, true)
        
    }
    
    addMessage(e) {
        e.preventDefault()        
        const message = this.refs._message.value
        this.props.addMessage(message)
        
    }
    
    showConversationById(e, conversationId) {
        e.preventDefault()     
        this.props.showConversationById(conversationId)
    }    
    
   
    render() {
        const {
            mode,
            user,
            conversation,
            briefConversationList
        } = this.props
        
        if (mode == "explainConversation") {

            return ( 
                <div>
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2 welcome">
                                You can soon start exchanging messages about a room.<br/> 
                                To send a message please register and/or log on first.   
                            < /div>                
                        </div>
                </div>
                    

            )
        } else if (mode == "showConversation") {

            return ( 
                <div>
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                View add for <a href="javascript:void(0);" onClick={e => this.viewAdd(e, conversation.advertisementId)}>{conversation.advertisementTitle}</a>
                            </div> 
                        </div>
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
                
                
                        {conversation.messages.map((each, index) => (
                            each[2] != user.id ?
                            <div key={index} className = "flexBox" >
                                <div className = "cellLeft50" ><b>{user.firstname} {user.lastname}</b> (on {each[3]}): {each[0]}< /div> 
                                <div className = "cellRight50" > 
                                    &nbsp;
                                </div>
                            </div>
                            :
                            <div key={index} className = "flexBox" >
                                <div className = "cellLeft50" >&nbsp;< /div> 
                                <div className = "cellRight50" ><b>{conversation.conversationPartner}</b>: {each[0]}</div>
                            </div>
                        ))}
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <textarea name="message" ref = "_message" />
                            </div> 
                        </div>
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <button onClick={e => {this.addMessage(e)}}> Send message < /button>    
                            < /div> 
                        </div>
        
       
                
                        
                </div>
                    
            )

        } else if (mode=="messages") {
            return (
                <div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 welcome" > 
                            My Messages
                        </div>
                    </div> 
                
                                {briefConversationList.conversations.map((each, index) => (
                                <div className = "flexBox" >
                                     <div className = "cellSpan2" key={index}> 
                                        <a href="javascript:void(0);"  onClick={e => this.showConversationById(e, each[0])}> {each[2]} {!each[3] ? "" : ""} </a> 
                                    </div>
                                </div>
                                 ))}
                
                </div>
            )
        } else {
            return null
        }




    }

}


Conversation.propTypes = {
    mode: PropTypes.string,
    user: PropTypes.object,
    conversation: PropTypes.object,
    addMessage: PropTypes.func,
    viewAdd: PropTypes.func,
    showConversationById: PropTypes.func,    
    briefConversationList: PropTypes.object
}


Conversation.defaultProps = {
    addMessage: f => f,    
    viewAdd: f => f,
    showConversationById: f => f
}

export default Conversation
