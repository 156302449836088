import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import '../css/slides.css';


class Display extends Component {

   
    
    constructor(props) {
        super(props)
       
        this.state = {
        }
    }
    
    
   
    
    render() {
        const {
            displayUrl,
            mode,
            user
        }  = this.props 
        
        if (mode == "showAddList") {
            return ( 
                
                
                <div>
                
                       
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                             <img src={displayUrl} className="display" />
                        </div> 
                    </div>
                    
                
                
                    <div className = "flexBox" >
                        <div className = "cellSpan2 welcome" > 
                            Welcome to Staydler
                        </div>
                    </div>
                    
                
                    
                <
                div className = "flexBoxNoWrap" >
                <
                div className = "cellLeft50 morePadding" > 
                    <p className="landingPageHeader">Renter</p>
                    Looking for a <b>short-term or medium-term room to rent</b> in Belgium (Brussels, Leuven or Gent) ? We’ve got you covered.
                    <br/><br/>
                    Browse our free <b>listings of rooms</b>. 
                    Sign up and exchange messages with the landlord instantly.
                </div> 
                <
                div className = "cellRight50 morePadding" > 
                    <p className="landingPageHeader">Owner</p>
                    Are you a room owner in Brussels, Leuven or Gent, and looking to maximize your property’s potential? Staydler is your partner for <b>listing your room</b> for free.
                    <br/><br/>
                    Sign up and showcase your space with detailed descriptions and high-quality photos to young professionals, students and travelers alike. 
                </div>                             
                </div>
                </div>
            )

        } else if (mode=="welcomeLoggedOn") {
            return (
                                        
                    <div>
                
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                             <img src={displayUrl} className="display" />
                        </div> 
                    </div>
                
                
                    <div className = "flexBox" >
                        <div className = "cellSpan2 welcome" > 
                            Welcome {user.lastname} {user.firstname}
                        </div>
                    </div>
                    
                
                    
                <
                div className = "flexBoxNoWrap" >
                <
                div className = "cellLeft50 morePadding" > 
                    <p className="landingPageHeader">Renter</p>
                    Are you looking for a <b>short-term or medium-term room to rent</b> ?
                    <br/><br/>
                    Browse our single rooms, co-housing and shared apartements today and discover your next cozy space. Exchange messages with the landlord for free.                     
                </div> 
                <
                div className = "cellRight50 morePadding" > 
                    <p className="landingPageHeader">Owner</p>
                    Start by Creating your listing.<br/><br/>
                    Wait and come back to view messages of potential candidates.
                </div>                             
                </div>
                </div>
                    )
        } else if (mode=="preferences" || mode=="modifyProfile" || mode=="modifyUser" || mode=="logon" || mode=="subscribe" || mode=="explainConversation") {
            return (
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                             <img src={displayUrl} className="display" />
                        </div> 
                    </div>
                )
        } else {
            return null
        }
        
        
    
    }
    
}

Display.propTypes = {
    mode: PropTypes.string,
    user: PropTypes.string,
    displayUrl: PropTypes.string
}


Display.defaultProps = {
}

export default Display
    