import React, { useState, useEffect } from 'react';
import '../css/content.css';
import {GOOGLE_API_KEY} from '../config'
import {useLoadScript, GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import "npm-overlapping-marker-spiderfier";

const MapSearchContainer = ({addList,  viewAdd=f=>f, selectedCityLatitude, selectedCityLongitude}) => {
  
  const [ selected, setSelected ] = useState({});
  
  const mapStyles = {        
    height: "350px",
    width: "100%"
  };
  
  const defaultCenter = {
    lat: selectedCityLatitude,
    lng: selectedCityLongitude
  }
      
  const [oms, setOms] = useState(null); 
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [photo, setPhoto] = useState(null);      
  const [rate, setRate] = useState(null); 
  const [id, setId] = useState(null);      
  const [description, setDescription] = useState(null);       
  const [accomodationType, setAccomodationType] = useState(null);
  const [dateAvailableFrom, setDateAvailableFrom] = useState(null); 
  const [infoOpen, setInfoOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);      
  const {isLoaded,loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY
  });
    
 const onLoad = map => {
     try {
              
              //alert("loading now, isMounted = "+isMounted)
              const oms1 = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
              var newOms = new oms1.OverlappingMarkerSpiderfier(map, {
              markersWontMove: true, // we promise not to move any markers, allowing optimizations
              markersWontHide: true, // we promise not to change visibility of any markers, allowing optimizations
              basicFormatEvents: true // allow the library to skip calculating advanced formatting information
              });
              setOms(newOms);
              //alert("oms set");
              setIsMounted(true);
         
  } catch(err) {
    console.log("error: "+err.message);
  }
 };
    
 const markerClickHandler = (event, place, marker, photo, rate, id, description, accomodationType, dateAvailableFrom) => {
    // Remember which place was clicked
    // if (infoOpen) {
    setInfoOpen(false);
    // }
    setSelectedMarker(marker);
    setSelectedPlace(place);
    setPhoto(photo)
    setRate(rate)
    setId(id)
    setDescription(description)     
    setAccomodationType(accomodationType)
    setDateAvailableFrom(dateAvailableFrom)

    setInfoOpen(true);
  };
    

  
const renderMap = () => {
    
    
  if (addList.length > 0) {
  
      
  return (
     
    
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={mapStyles}
          zoom={12}
          center={defaultCenter}
          onUnmount={map => {
               map = null;
            }}
        >
           {
            isMounted && addList.map((item, index) => {
            
      
            return (
            <Marker key={index} title={"test"} position={{lat:item?.address?.latitude,lng:item?.address?.longitude}} onLoad={marker => {
              oms.addMarker(marker);
              //alert("marker")
              window.google.maps.event.addListener(
                marker,
                "spider_click",
                e => {
                  markerClickHandler(e, {lat:item?.address?.latitude,lng:item?.address?.longitude}, marker, item?.photos[0], item?.rentFee + item?.rentCharges, item?.advertisementId, item?.briefDescription, item?.accomodationType, item?.dateAvailableFrom);
                }
              );                                                                                                 
              }}  
            /> )
             
            })
            } 
            
            {infoOpen && selectedMarker && (
                  <InfoWindow 
                    position={selectedPlace}
                    anchor={selectedMarker}
                    onCloseClick={() => setInfoOpen(false)}                    
                    >
                    <div>
                        <img src={photo} height='90px' /><br/>
                        {accomodationType}<br/>
                        Available from: {dateAvailableFrom}<br/>
                        Monthly fee: <b>{rate}</b><br/>
                        <a onClick={e => viewAdd(id)} className="addLink" >{description}</a>
                    </div>
                  </InfoWindow>
            )}
        </GoogleMap>
     
  )
  
  } else {
      return <div>"Currently no advertisements."</div>
  }
  }
    
  if (loadError) {
    //alert("error")
    return <div>Map cannot be loaded right now, sorry.</div>;
  } else { 
    return isLoaded ? renderMap() : null;
  }
}

export default MapSearchContainer;