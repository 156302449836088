import React, { Component } from "react";
import '../css/content.css';

export default class Popup extends Component {
  
  handleClick = () => {
   this.props.togglePopup();
  }
  
  render() {
      return (
       <div className="modal">
         <div className="modal_content">
         <span onClick={this.handleClick}><p className="close">&times;</p></span>
         <p>
          <b>Terms of Use Agreement</b>
          <ol start="1">
            <li>1. Acceptance of Terms
            By accessing and using our room rental website, you agree to comply with these Terms of Use. If you do not agree with any part of these terms, please refrain from using our services.
            </li>
            <li>2. User Responsibilities
            You must be at least 18 years old to use our website.
            You are responsible for maintaining the confidentiality of your account information.
            You agree not to misuse our services or engage in any unlawful activities.
            </li>
            <li>3. Property Listings
            Property owners are solely responsible for the accuracy of their room listings.
            We do not endorse or verify the quality, safety, or legality of any listed properties.
            </li>
            <li>4. Intellectual Property
            All content on our website is protected by copyright and other intellectual property laws.
            Users may not reproduce, modify, or distribute any content without permission.
            </li>
            <li>5. Limitation of Liability
            We are not liable for any damages arising from the use of our website or any listed properties.
            Users assume all risks associated with room rentals.
            </li>
            <li>6. Dispute Resolution
            Any disputes between users and property owners should be resolved amicably.
            We are not responsible for resolving disputes.
            </li>
            <li>7. Changes to Terms
            We reserve the right to modify these terms at any time.
            Users will be notified of any changes.
            </li>
          </ol>
          </p>
        </div>
       </div>
      )
    }
}