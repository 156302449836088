import { CLIENT_ID } from '../config'
import React, { useState, useEffect } from "react" ;
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import '../css/content.css';

const Checkout = ({mode, credits, buyCredits=f=>f}) => {

    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const [noCredits, setNoCredits] = useState(0);
    const [price, setPrice] = useState(0);
    
    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Conversation pack",
                    amount: {
                        currency_code: "USD",//TO DO: set currency to EUR
                        value: price,
                    },
                },
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };
    
    const addCredits = (e) => {
       
        setNoCredits(e.currentTarget.value)
        
    }
    
    const setThePrice = () => {
        if (noCredits == 1) {
            setPrice(3)
        } else if (noCredits == 5) {
            setPrice(10)
        }
    }

    useEffect(() => {
        if (success) {
            alert("Payment successful");
            console.log('Order successful . Your order id is--', orderID);
            buyCredits(noCredits)
            
        }        
    },[success]);
    
    useEffect(() => {
       if (noCredits!=0) {
            setThePrice()
            
        }
    },[noCredits]);
    
    
    useEffect(() => {
       if (price!=0) {
            setShow(true)
            
        }
    },[price]);

    return (
        
        <div>
        
                        {(mode=="confirmCredits") ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                Payment succeeded. You have bought conversation credits.<br/>
                                A receipt has been sent to your e-mail address.
                            </div>
                        </div>
                        : ""}
        
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                You own {credits} conversation credit(s).
                            </div>
                        </div>
        
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div>
                        </div>

                        {(mode=="credits") ?
                            <div className = "flexBox" >
                                <div className = "cellLeft50" >
                                    <input type="radio" id="buyOption" name="buyOption" value="1" onChange={addCredits} /> Buy 1 Credit: 3 €           
                                < /div> 
                                <div className = "cellRight50">
                                    <input type="radio" id="buyOption" name="buyOption" value="5" onChange={addCredits} /> Buy 5 Credits: 10 €           
                                </div>
                            </div>
                        : ""}
        
                        {(mode=="credits" && show) ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
                                    <div>
                                        <PayPalButtons
                                                style={{ layout: "vertical" }}
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                            />

                                    </div>
                                </PayPalScriptProvider>
                            < /div>                
                        </div>
                        : ""}
                    
                </div>
    );
}

export default Checkout