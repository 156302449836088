import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import '../css/slides.css';
import ShortAdvertisement from './ShortAdvertisement'
import MapSearchContainer from './MapSearchContainer'
import Search from './Search'
import {Helmet} from "react-helmet";

class AdvertisementList extends Component {

   
    
    constructor(props) {
        super(props)
        this.viewAdd = this.viewAdd.bind(this)
        this.changeCountry = this.changeCountry.bind(this)
        this.changeCity = this.changeCity.bind(this)
        this.showAddList = this.showAddList.bind(this)
       
        
        this.state = {
        }
        
        
    }
    
    
    viewAdd(advertisementId) {                
        this.props.viewAdd(advertisementId)
        
        
    }
    
        
    showAddList(e, user, searchMode, rowStart) {
        e.preventDefault       
        this.props.showAddList(user, searchMode, rowStart)
    } 
    
    
    changeCountry(e) {
        e.preventDefault
        
        this.props.changeCountry(e)
    }
    
    changeCity(e, searchUser) {
        e.preventDefault
        //alert("change city")
        this.props.changeSearchCity(e, searchUser)
    }
    
    
    render() {
        const {            
            addList,
            searchMode,
            countryList,
            selectedCountry,
            cityList,
            selectedCity,
            selectedCityLatitude,
            selectedCityLongitude,
            searchUser,
            rowStart,
            rowBatchCount,
            rowTotal
        }  = this.props
        
        var rows = []
        
        for (let i = 0; i < rowTotal; i+=rowBatchCount) {
            rows.push(i)
        }
        
        var key = 0;
        
        var halfwayPoint = Math.ceil(addList.length / 2)
        var columnA = addList.slice(0, halfwayPoint)
        var columnB = addList.slice(halfwayPoint)
        
    
        return (
                    <div>
                        <Helmet>
                          <meta charSet="utf-8" />
                          <title>Staydler room advertisement listing</title>
                          <link rel="canonical" href="http://staydler.com" />
                          <meta name="description" content="short or medium term room to rent advertisement listing in Belgium Brussels Ghent Leuven"/>
                        </Helmet>
                        <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            {searchUser == null ?
                            <div>Advertisements</div>
                            :
                            <div>My Advertisements</div>
                            }
                            
                        </div> 
                        </div>
            
                        {searchMode == "searchAndResults" || searchMode == "searchMap" ||  searchMode == "loading"?                        
                                <Search searchMode={searchMode} countryList={countryList} cityList={cityList} selectedCountry={selectedCountry} selectedCity={selectedCity}
                                        changeCountry={this.changeCountry} changeCity={this.changeCity} searchUser={searchUser} />                            
                        : ""
                        }
            
            
                        {searchMode == "loading" ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formLabel">
                                Loading
                            </div> 
                        </div>
                        :""
                        }
            
                        {searchMode == "searchAndResults" || searchMode == "resultsOnly" ?
                        <div>
                        {addList.length > 0 ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <a href="javascript:void(0);" onClick={e => this.showAddList(e, searchUser,"searchMap", 0) }>Show results on map</a>
                            </div> 
                        </div>
                        :"Currently no advertisements."
                        }
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
                        <div className = "flexBox" >
                            <div className = "cellLeft50 roundedBorder" >
                              {columnA.map((add, i) => {
                                  key++;
                                  return (
                                   
                                        <ShortAdvertisement key={key} add={add} viewAdd={this.viewAdd}/>
                                     
                                  )
                                })
                              }
                            </div> 
                            <div className = "cellRight50 roundedBorder" >
                              {columnB.map((add, i) => {
                                  key++;
                                  return (
                                   
                                        <ShortAdvertisement key={i} add={add} viewAdd={this.viewAdd}/>
                                        
                                  )
                                })
                              }
                            </div>
                        </div>
                        {rowTotal > rowBatchCount ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                {
                                    
                                    rows.map((row, i) => {
                                        return( <a key={i} href={undefined} onClick={e => this.showAddList(e, searchUser, "searchAndResults", row)} 
                                        className={(rowStart == i+(i*(rowBatchCount-1))) ? "pageSelected" : "page"}> page {i+1} </a> )
                                       
                                    })
                        
                                }
                            </div> 
                        </div>
                        : 
                        ""
                        }
                        </div>
                        : ""
                        }
                        {searchMode == "searchMap" ?
                        <div>
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                               <a href="javascript:void(0);" onClick={e => this.showAddList(e, searchUser, "searchAndResults", 0) }>Show results in list</a>
                            </div> 
                        </div>
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                              &nbsp;
                            </div> 
                        </div>
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <MapSearchContainer addList={addList}  viewAdd={this.viewAdd} selectedCityLatitude={selectedCityLatitude} selectedCityLongitude={selectedCityLongitude}/>
                            </div> 
                        </div>
                        </div>
                        : ""
                        }
                    </div>
                        
                    
        )
    }
                                
                        
}

AdvertisementList.propTypes = {
    advertisementList: PropTypes.array,
    showAdd: PropTypes.func,
    countryList: PropTypes.array,
    cityList: PropTypes.array,
    selectedCountry: PropTypes.number,
    selectedCity: PropTypes.number,
    selectedCityLatitude: PropTypes.number,
    selectedCityLongitude: PropTypes.number,
    changeCountry: PropTypes.func,
    changeSearchCity: PropTypes.func,
    searchUser: PropTypes.number,
    showAddList: PropTypes.func,
    rowStart: PropTypes.number,
    rowBatchCount: PropTypes.number,
    rowTotal: PropTypes.number
    
}


AdvertisementList.defaultProps = {    
    showAdd: f => f,
    changeCountry: f => f,
    changeSearchCity: f => f,
    showAddList: f => f
}

export default AdvertisementList
