import {
    Component
} from 'react';
import '../css/menu.css';
import PropTypes from 'prop-types'



class Menu extends Component {

    constructor(props) {
        super(props)
        this.setMode = this.setMode.bind(this)
        this.logoff = this.logoff.bind(this)
        this.showPreferences = this.showPreferences.bind(this)
        this.buyCredits = this.buyCredits.bind(this)
        this.showModifyProfile = this.showModifyProfile.bind(this)
        this.showModifyUser = this.showModifyUser.bind(this)
        this.bindClickToggleEvent = this.bindClickToggleEvent.bind(this)
        this.clickToggle = this.clickToggle.bind(this)
        this.showAddList = this.showAddList.bind(this)
        
        
        
        
        this.state = {
        }
    }

    
    
    setMode(e, mode) {
        this.clickToggle(e)
        this.props.setMode(mode)
        
    }
    
    
    showPreferences(e) {
        this.clickToggle(e)
        this.props.showPreferences()
    }
    
    showModifyProfile(e) {
        this.clickToggle(e)
        this.props.showModifyProfile()
    }
    
    showModifyUser(e) {
        this.clickToggle(e)
        this.props.showModifyUser()
    }
    
        
    logoff(e) {
        this.clickToggle(e)
        this.props.logoff()
        
    }
    
    showAddList(e,user, searchMode, rowStart) {
        this.clickToggle(e)       
        this.props.showAddList(user, searchMode, rowStart)
        
    }
        
    buyCredits(e) {
        this.setMode(e, "credits")
    }
    
    bindClickToggleEvent() {
            var button = document.getElementById('toggle-menu');
            button.addEventListener('click', function(event) {
                event.preventDefault();
                var menu = document.getElementById('main-menu');
                menu.classList.toggle('is-open');
            });
            
    }
    
    clickToggle(e) {
        e.preventDefault();
        var menu = document.getElementById('main-menu');
        menu.classList.toggle('is-open');
        
    }
    
    
    componentDidMount() {
        this.bindClickToggleEvent();
    }
    
    //TO DO: reset advertisement before "Create adverisement"
    render() {
        const {
            user
        } = this.props 
       
        return (
             <nav className="menu" id="main-menu">
                <button className="menu-toggle" id="toggle-menu">
                    <img src="./images/user.jpg" />
                </button>
                <div className="menu-dropdown">
                    <ul className="nav-menu">
                        {user.id < 0 ?
                            <li><a href="javascript:void(0);" onClick={(e) => this.setMode(e, "subscribe")}>Register</a></li>
                        : ""}
                        {user.id < 0 ?
                            <li><a href="javascript:void(0);" onClick={(e) => this.setMode(e, "logon")}>Log on</a></li>
                        : ""}
                        {user.id > 0 && user.authority == "ADMIN" ?
                             <li><a href="javascript:void(0)"  onClick={e => this.showAddList(e, -1, "resultsOnly", -1)} > Verify advertisements </a></li>
                        : ""}
                        {user.id >= 0 ?
                            <li><a href="javascript:void(0);"   onClick={e => this.showPreferences(e)}> Preferences </a></li>
                        : ""}
                        {user.id >= 0 ?
                            <li><a href="javascript:void(0);"   onClick={e => this.showModifyProfile(e)}> Modify profile </a></li>
                         : ""}    
                        {user.id >= 0 ?
                            <li><a href="javascript:void(0);"   onClick={e => this.showModifyUser(e)}> Modify e-mail / username </a></li>
                         : ""}       
                        {user.id >= 0 ?
                            <li><a href="javascript:void(0);" onClick={(e) => this.logoff(e)}>Log off</a></li>
                        : ""}        
                                

                    </ul>
                </div>
             </nav>
        )
    }
}

Menu.propTypes = {
    user: PropTypes.object,
    briefConversationList: PropTypes.object,
    setMode: PropTypes.func,
    logoff: PropTypes.func,
    showPreferences:  PropTypes.func, 
    showAddList: PropTypes.func,
    showModifyUser:  PropTypes.func
}


Menu.defaultProps = {
    setMode: f => f,
    logoff: f => f,    
    showPreferences: f => f,
    showAddList: f => f,
    showModifyUser: f => f
}

export default Menu
