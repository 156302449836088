//TO DO: move state to parent Main.js
import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import PhotoSelect from './PhotoSelect'
import '../css/slides.css';
import Slideshow from './Slideshow'
import MapContainer from './MapContainer'
import {validateAddress} from '../api/api'
import {FacebookShareButton, FacebookIcon} from 'react-share';

//Global variable to store formData
let formData = null


class Advertisement extends Component {

   
    
    constructor(props) {
        super(props)
        //this.selectAdvertisement = this.selectAdvertisement.bind(this)
        this.setMode = this.setMode.bind(this)
        this.countUploadedPhotos = this.countUploadedPhotos.bind(this)
        this.onChangeText = this.onChangeText.bind(this)
        this.onChangeAddress = this.onChangeAddress.bind(this)
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.postAdvertisement = this.postAdvertisement.bind(this)
        this.deletePhoto = this.deletePhoto.bind(this)
        this.updateAdvertisementSlot = this.updateAdvertisementSlot.bind(this)
        this.changeCountry = this.changeCountry.bind(this)
        this.changeCity = this.changeCity.bind(this)
        this.changeDistrict = this.changeDistrict.bind(this) 
        this.showConversationByAddId = this.showConversationByAddId.bind(this)
        this.deleteAdd = this.deleteAdd.bind(this)
        this.validateAddress = this.validateAddress.bind(this)
        this.sendAdvertisementMessage = this.sendAdvertisementMessage.bind(this)
        
        
        
     

        this.state = {
            selectedCountry: this.props.selectedCountry,
            districtUpdated:false,
            countryCode:"",
            district:"",
            zipCode:"",
            street:"",
            streetNo:"",
            textFieldsOk:true,
            briefDescriptionOk:true,
            streetOk:true,
            streetNumberOk:true,
            rentFeeOk:true,
            dateFromOk:true,
            dateToOk:true
        }
    }


    componentDidUpdate(prevProps, prevState) {

        //alert("select district length:"+this.districtElement.length)
        
        if (prevProps.selectedCountry != prevState.selectedCountry) {
            //alert("changing city from country")
            this.cityElement.addEventListener('change',this.changeCity)
            this.cityElement.dispatchEvent(new Event("change"))
                
            let state = {
                ...this.state,
                selectedCountry: prevProps.selectedCountry
            }
            this.setState(state)
            
        }
        
        
        if ((this.districtElement) && (this.districtElement.length > 0) && !this.state.districtUpdated) {
            //alert("changing district from didupdate")
            this.districtElement.addEventListener('change',this.changeDistrict)
            this.districtElement.dispatchEvent(new Event("change"))
        
            let state = {
                ...this.state,
                districtUpdated: true
            }
            this.setState(state)
        
        } 
        
        
    }
    
        
    countUploadedPhotos() {
      return this.props.countUploadedPhotos()
    }
    
    
    setFormData(passFormData) {
        
        formData = passFormData
        
        for (const key of formData.keys()) {
            console.log(key);
        }
    }

    
    
    setMode(e, mode) {
        e.preventDefault()
        this.props.setMode(mode)
    }
    
    deleteAdd(e, advertisementID) {
        e.preventDefault()
        if (confirm("Are you certain you wish to delete the advertisement ? Deleting the advertisement will also remove all conversations related to this advertisement. This action cannot be undone.") == true) {
            this.props.deleteAdd(advertisementID)
        }
    }
    
    
    
    onChangeText(e) {
        e.preventDefault
        this.props.onChangeText(e)
        
    }
    
    onChangeAddress(e) {
        e.preventDefault
       
        if (e.target.id == "streetNumber") {   
        
            const countryRef = this.refs._country
            
            var countryText = countryRef.options[countryRef.selectedIndex].text
            var countryCode = countryText.substring(0, countryText.indexOf(" "))
            
            var districtText = this.districtElement.options[this.districtElement.selectedIndex].text
            var zipCode = districtText.substring(0, districtText.indexOf(" "))
            var district = districtText.substring(districtText.indexOf(" "))
            
            
            const street = this.refs._street.value
            const streetNumber = this.refs._streetNumber.value
                      
                        
            let address = {
                ...this.state,
                street:street,
                streetNo:streetNumber,
                district:district,
                zipCode:zipCode,
                countryCode:countryCode
            }
            
            //this.validateAddress(address)
            validateAddress(address).then(response => {
                console.log(response)
                
                if (response?.result?.verdict?.addressComplete) {
                    
                        let addressState = {
                            street:street,
                            streetNumber:streetNumber,
                            longitude:response.result.geocode.location.longitude,
                            latitude:response.result.geocode.location.latitude
                        }
                
                        this.props.onChangeAddress(addressState)
                
                        
                } else {
                    var ok = confirm("We could not completely validate the address street and street number. The geographic map displayed with the advertisement may not be precise. If you wish to proceed with the current address, click ok, else if you wish to re-enter the street and street number click cancel.")
                    
                    if (ok) {
                        let addressState = {
                            street:street,
                            streetNumber:streetNumber,
                            longitude:response.result.geocode.location.latitude,
                            latitude:response.result.geocode.location.longitude
                        }
                
                
                
                        this.props.onChangeAddress(addressState)
                
                        
                    }                          
                }
                
            }).catch(error => {console.error(error);})
        
        }
        
            
    }
    
    validateAddress(state) {
        validateAddress(state).then(response => {
                console.log(response)
            }).catch(error => {console.error(error);})
    }
    
    onChangeCheckbox(e) {
        
            e.preventDefault
            this.props.onChangeCheckbox(e)
            
    }
    
    
    
    changeCountry(e) {
        e.preventDefault
        
        let state = {
                ...this.state,
                districtUpdated: false
        }
        this.setState(state) 
        
        this.districtElement.style.visibility="hidden"
        this.props.changeCountry(e)
    }
    
    changeCity(e) {
        //alert("changing city")
        e.preventDefault
        
        
        let state = {
                ...this.state,
                districtUpdated: false
        }
        this.setState(state) 
        
                
        this.districtElement.style.visibility="visible"
        this.props.changeCity(e)
    }
    
        
    changeDistrict(e) {
                
        e.preventDefault        
        this.props.changeDistrict(e)
        
    }
    
    
    postAdvertisement(e) {
        
        e.preventDefault
        
        let state = {
            ...this.state,
            textFieldsOk:true,
            briefDescriptionOk:true,
            streetOk:true,
            streetNumberOk:true,
            rentFeeOk:true,
            dateFromOk:true,
            dateToOk:true
        }
        
        this.setState(state)

        
        var datesOk = true
        var textFieldsOk = true
        const briefDescription = this.refs._briefDescription.value
        var briefDescriptionOk = true
        const street = this.refs._street.value
        var streetOk = true
        const streetNumber = this.refs._streetNumber.value
        var streetNumberOk = true
        const rentFee = this.refs._rentFee.value
        var rentFeeOk = true
        
        
        if (briefDescription.length == 0) {
            briefDescriptionOk = false
            textFieldsOk = false
        }
        if (street.length == 0) {
            streetOk = false
            textFieldsOk = false
        }
        if (streetNumber.length == 0) {
            streetNumberOk = false
            textFieldsOk = false
        }
        if (rentFee.length == 0 || rentFee == "0") {
            rentFeeOk = false
            textFieldsOk = false
        }
        
        state = {
            ...this.state,
            textFieldsOk:textFieldsOk,
            briefDescriptionOk:briefDescriptionOk,
            streetOk:streetOk,
            streetNumberOk:streetNumberOk,
            rentFeeOk:rentFeeOk
        }
        
        this.setState(state, () => {
            if (!textFieldsOk) {
                var element = document.getElementById('warning');
                element.innerHTML = "Fields marked in red are mandatory."
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            } else {
            
                const dateAvailableFrom = this.refs._dateAvailableFrom.value

                var now = new Date()
                now.setHours(0,0,0,0)

                if(Date.parse(dateAvailableFrom)-Date.parse(now)<0) {

                    datesOk = false

                    let state = {
                        ...this.state,
                        dateFromOk:false,
                        dateToOk:true
                    }

                    this.setState(state, () => {

                            var element = document.getElementById('warning');
                            element.innerHTML = "Date available from must be in the future."
                             if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });

                            }
                    })


                }
            
                if (datesOk) {

                    const dateAvailableTo = this.refs._dateAvailableTo.value



                    if(Date.parse(dateAvailableTo)-Date.parse(dateAvailableFrom)<=0) {

                    datesOk = false

                    let state = {
                        ...this.state,
                        dateFromOk:true,
                        dateToOk:false
                    }

                    this.setState(state, () => {
                        var element = document.getElementById('warning');
                        element.innerHTML = "Date available to must be later than date available from"
                        if (element) {
                                    element.scrollIntoView({ behavior: 'smooth' });

                        }
                    })




                    }
                }
        
                if (datesOk) {
                    
                    if (this.props.countUploadedPhotos == 0) {
                        var element = document.getElementById('warning');
                        element.innerHTML = "You must select at least one photo to upload."
                        if (element) {
                                    element.scrollIntoView({ behavior: 'smooth' });

                        }
                    } else {
                        
                        let state = {
                            ...this.state,
                            textFieldsOk:true,
                            briefDescriptionOk:true,
                            streetOk:true,
                            streetNumberOk:true,
                            rentFeeOk:true,
                            dateFromOk:true,
                            dateToOk:true
                        }

                        this.setState(state)
                        this.props.postAdvertisement(formData)
                        formData = null
                        this.child.clear()
                    }
                }
            
            }
        })
         
        
        
        

    }
    
    updateAdvertisementSlot(e, advertisementId, active, verified) {
        e.preventDefault
        
        this.props.updateAdvertisementSlot(advertisementId, active, verified)
    }
    
    deletePhoto(photoURI) {
        
        this.props.deletePhoto(photoURI)
        
    }
    
    showConversationByAddId(e) {
        e.preventDefault
        this.props.showConversationByAddId()
    }
    
    sendAdvertisementMessage(e, advertisementID) {
        e.preventDefault
        
        const message = this.refs._message.value
        
        this.props.sendAdvertisementMessage(advertisementID, message)
        
    }
    
    
    
    render() {
        const {
            mode,
            advertisement,
            user,
            countryList,
            cityList,            
            districtList,
            selectedCountry,
            selectedCity,
            selectedDistrict
        }  = this.props 
       
        if (mode == "updateAdd") {
        return (
            

                 <div>   
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <p id="warning" className={"badInput"}></p>
                        </div> 
                    </div>
            
            
                    <div className = "flexBox" >
                        <div className = "cellSpan2 alignRight">< a href = {
                            undefined
                        }
                        onClick = {
                            e => this.setMode(e, "welcome")
                        }>[X]< /a>
                        </div> 
                    </div>
        
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            General information
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>      
        
                    <div className = "flexBox" >
                        <div className = "cellSpan2 star">
                            (*) mandatory field
                        </div> 
                    </div>      
        
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>      
        
                     
                    <div className = "flexBox" >
                        <div className = "cellLeft50" > 
                            <p className={this.state.briefDescriptionOk ? 'formLabel': "badInput"}>Brief Description <font className="star">*</font>:</p>
                        < /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.briefDescription} id="briefDescription" onBlur={e => {this.onChangeText(e)}} ref="_briefDescription" />    
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            Address
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Country:< /div> 
                        <div className = "cellRight50" > 
                            <select id="country" onChange={e => {this.changeCountry(e)}} value={selectedCountry} ref="_country">
                                {countryList.map((country, index) => (
                                    <option key={index} label={country.countryCode+" "+country.country} value={country.countryId} >{country.countryCode} {country.country}</option> 
                                 ))                                
                                }                           
                            </select>
                        < /div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >City:< /div> 
                        <div className = "cellRight50" > 
                            <select id="city" onChange={e => {this.changeCity(e)}} value={selectedCity}
                                ref={(input) => this.cityElement = input}>
                                {cityList.map((city, index) => (
                                    <option key={index} value={city.cityId} >{city.city}</option> 
                                 ))                                
                                }                               
                            </select>
                        < /div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >District:< /div> 
                        <div className = "cellRight50" > 
                            <select id="districtId" onChange={e => {this.changeDistrict(e)}} value={selectedDistrict} 
                                ref={(input) => this.districtElement = input}>
                                {districtList.map((district, index) => (
                                    <option key={index} label={district.zipCode+" "+district.district} value={district.districtId} >{district.zipCode} {district.district}</option> 
                                 ))                                
                                }
                            </select>
                        < /div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellLeft50" >
                            <p className={this.state.streetOk ? 'formLabel': "badInput"}>Street <font className="star">*</font>:</p>< /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.address?.street} id="street" onBlur={e => {this.onChangeAddress(e)}} ref="_street" />    
                        < /div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellLeft50" >
                            <p className={this.state.streetNumberOk ? 'formLabel': "badInput"}>Street number <font className="star">*</font>:</p>< /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.address?.streetNumber} id="streetNumber" onBlur={e => {this.onChangeAddress(e)}} ref="_streetNumber"/>    
                        < /div> 
                    </div>
                    
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50" >Accomodation type:< /div> 
                        <div className = "cellRight50 formLabel" > 
                            <select id="accomodationType" onChange={e => {this.onChangeText(e)}} value={this.props.advertisement.accomodationType}>
                                <option value="co-housing" >co-housing</option>
                                <option value="single occupation" >single occupation</option>
                            </select>
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            Commodoties
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Surface area:< /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.surfaceArea} id="surfaceArea" onBlur={e => {this.onChangeText(e)}} size="4" /> m²    
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="wifi" value="wifi" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.wifi == true}/> Wifi       
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            <input type="checkbox" id="kitchen" value="kitchen" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.kitchen == true}/> Kitchen
                        < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <input type="checkbox" id="microwave" value="microwave" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.microwave == true}/> Microwave <br/>
                            <input type="checkbox" id="stove" value="stove" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.stove == true}/> Stove <br/>
                            <input type="checkbox" id="fridge" value="fridge" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.fridge == true}/> Fridge <br/>
                            <input type="checkbox" id="oven" value="oven" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.oven == true}/> Oven <br/>    
                        < /div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="bedlinnen" value="bedlinnen" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.bedlinnen == true}/> Bedlinnen       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="bathroom" value="bathroom" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.bathroom == true}/> Bath tub       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="shower" value="shower" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.shower == true}/> Shower       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="laundry" value="laundry" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.laundry == true}/> Laundry machine       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="dryer" value="dryer" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.dryer == true}/> Drying machine       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="vacuumCleaner" value="vacuumCleaner" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.vacuumCleaner == true}/> Vacuum cleaner       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="ironingBoard" value="ironingBoard" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.ironingBoard == true}/> Ironing board       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="bikeStorage" value="bikeStorage" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.bikeStorage == true}/> Bike storage       
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            Audience
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                  
                  <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            <input type="checkbox" id="audienceInterns" value="audienceInterns" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.audienceInterns == true}/> Interns / Stagiares
                        < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <input type="checkbox" id="audienceStudents" value="audienceStudents" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.audienceStudents == true}/> Students                            
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            <input type="checkbox" id="audienceGirlsOnly" value="audienceGirlsOnly" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.audienceGirlsOnly == true}/> Girls only
                        < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <input type="checkbox" id="audienceBoysOnly" value="audienceBoysOnly" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.audienceBoysOnly == true}/> Boys only                           
                        < /div> 
                    </div>
                  
                  <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Non smokers only: < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <div onChange={e => {this.onChangeText(e)}}>
                            <input type="radio" value="true" id="audienceNonSmokers" name="audienceNonSmokers" defaultChecked={this.props.advertisement.audienceNonSmokers == true} /> yes
                            <input type="radio" value="false" id="audienceNonSmokers" name="audienceNonSmokers" defaultChecked={this.props.advertisement.audienceNonSmokers == false} /> no
                            </div>
                        < /div> 
                    </div> 
                  
                  <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Couples allowed: < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <div onChange={e => {this.onChangeText(e)}}>
                            <input type="radio" value="true" id="audienceCouples" name="audienceCouples" defaultChecked={this.props.advertisement.audienceCouples == true} /> yes
                            <input type="radio" value="false" id="audienceCouples" name="audienceCouples" defaultChecked={this.props.advertisement.audienceCouples == false} /> no
                            </div>
                        < /div> 
                    </div>  
                  
                  <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Pets: < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <div onChange={e => {this.onChangeText(e)}}>
                            <input type="radio" value="true" id="audiencePets" name="audiencePets" defaultChecked={this.props.advertisement.audiencePets == true} /> yes
                            <input type="radio" value="false" id="audiencePets" name="audiencePets" defaultChecked={this.props.advertisement.audiencePets == false} /> no
                            </div>
                        < /div> 
                    </div>
                  
                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Domiciliation possible: < /div> 
                        <div className = "cellRight50 formLabel" > 
                            <div onChange={e => {this.onChangeText(e)}}>
                            <input type="radio" value="true" id="audienceDomiciliation" name="audienceDomiciliation" defaultChecked={this.props.advertisement.audienceDomiciliation == true} /> yes
                            <input type="radio" value="false" id="audienceDomiciliation" name="audienceDomiciliation" defaultChecked={this.props.advertisement.audienceDomiciliation == false} /> no
                            </div>
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            Duration
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>


                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            <p className={this.state.dateFromOk ? '': "badInput"}>Date available from:</p>
                        < /div> 
                        <div className = "cellRight50" > 
                            <input type="date" placeholder="dd-mm-yyyy" id="dateAvailableFrom" name="dateAvailableFrom" defaultValue={this.props.advertisement.dateAvailableFrom} onChange={e => {this.onChangeText(e)}} ref="_dateAvailableFrom" />
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            <p className={this.state.dateToOk ? '': "badInput"}>Date available to:</p>  
                        < /div> 
                        <div className = "cellRight50" > 
                            <input type="date" placeholder="dd-mm-yyyy" id="dateAvailableTo" name="dateAvailableTo" defaultValue={this.props.advertisement.dateAvailableTo} onChange={e => {this.onChangeText(e)}} ref="_dateAvailableTo" />
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >Minimum duration:< /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.minimumMonths} id="minimumMonths" onBlur={e => {this.onChangeText(e)}} size="4" /> months    
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            Rent
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50" >
                            <p className={this.state.rentFeeOk ? 'formLabel': "badInput"}>Rent fee <font className="star">*</font>:</p>< /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.rentFee} id="rentFee" onBlur={e => {this.onChangeText(e)}} size="4" ref="_rentFee"/> monthly    
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellLeft50 formLabel" >
                            Rent charges: < /div> 
                        <div className = "cellRight50" > 
                            <input type="text" defaultValue={this.props.advertisement.rentCharges} id="rentCharges" onBlur={e => {this.onChangeText(e)}} size="4" /> monthly    
                        < /div> 
                    </div>
  


                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">Detailed description:< /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <textarea id="detailedDescription" rows="10" cols="50" onBlur={e => {this.onChangeText(e)}} defaultValue={this.props.advertisement.detailedDescription}></textarea>
                        < /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">Advertisment is active/online:< /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <label className="switch">
                            <input type="checkbox" id="active" value="active" onChange={e => {this.onChangeCheckbox(e)}} defaultChecked={this.props.advertisement.active == true}/> 
                            <span className="slider round"></span>                           
                            </label>
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    

                    {this.countUploadedPhotos()>0 ?
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            Uploaded images
                        </div> 
                    </div>
                    : ""
                    }

                    {this.countUploadedPhotos()>0 ?
                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <Slideshow deletePhoto={this.deletePhoto} photos={this.props.advertisement.photos} hasDelete={true} />
                        </div> 
                    </div>
                    : ""
                    }



                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">Upload photo´s< /div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2" >
                            <PhotoSelect  setFormData={this.setFormData} ref={instance => { this.child = instance }}  countUploadedPhotos={this.props.countUploadedPhotos()} />        
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                    </div>

                    <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <button onClick={e => {this.postAdvertisement(e)}}> Submit < /button><br/><br/>
                            <button onClick = {e => this.setMode(e, "welcome")}> Cancel -- Home < /button>
                        < /div> 



                    </div>
                </div>
                    
        )
        } else if (mode == "viewAdd") {
            
            return (
                <div>
                
                
                        {(advertisement.userId == user.id) && (!advertisement.verified) ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2 star">
                                You created or updated your room listing, and uploaded some (new) photo´s.<br/>
                                A Staydler administrator will <b>verify and activate</b> your advertisement soon.<br/>
                                Meanwhile, the advertisement is not yet shown in the general listing. You are free however to update your listing again if needed, while it is pending verification.
                            < /div> 
                        </div>
                        :
                        ""
                        }

                        <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                        </div>
                
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formHeader">
                                <b>{this.props.advertisement.briefDescription}</b>   
                            < /div>                
                        </div>
                
                        <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                        </div>            

                        {this.countUploadedPhotos()>0 ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formHeader">
                                Photos
                            </div> 
                        </div>
                        : ""
                        }
            

                        {this.countUploadedPhotos()>0 ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <Slideshow deletePhoto={this.deletePhoto} photos={this.props.advertisement.photos} hasDelete={false} countUploadedPhotos={this.countUploadedPhotos()}/>
                            </div> 
                        </div>
                        : ""
                        }

                        <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                        </div>

                        <div className = "flexBox" >
                        <div className = "cellSpan2 formHeader">
                            Description
                        </div> 
                        </div>


                        <div className = "flexBox" >
                            <div className = "cellSpan2 formLabel">
                                {this.props.advertisement.detailedDescription}   
                            < /div>                
                        </div>

                        <div className = "flexBox" >
                        <div className = "cellSpan2">
                            &nbsp;
                        </div> 
                        </div>  
                
                        <div className = "flexBox" >
                            <div className = "cellLeft50 formLabel" > Accomodation type: < /div> 
                            <div className = "cellRight50 formLabel" > 
                                {this.props.advertisement.accomodationType}   
                            < /div>                
                        </div>
                
                        <div className = "flexBox" >
                            <div className = "cellLeft50 formLabel" > Monthly rent fee: <h1>{this.props.advertisement.rentFee} €</h1>< /div> 
                            <div className = "cellRight50 formLabel" >Monthly charges: <h1>{this.props.advertisement.rentCharges} €</h1>< /div>                
                        </div>
            
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formLabel">
                                {this.props.advertisement.surfaceArea} M² 
                            < /div>                
                        </div>
            
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formLabel">
                                Available from {this.props.advertisement.dateAvailableFrom} untill {this.props.advertisement.dateAvailableTo} (minimumum {this.props.advertisement.minimumMonths} months)   
                            < /div>                
                        </div> 
            

            
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
            
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formLabel">
                                <div className='new-line'>{this.props.advertisement.wifi ? <p><img src='./images/wifi.jpg'/> Wifi </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.bathroom ? <p><img src='./images/bath.jpg'/> Bathroom </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.shower ? <p><img src='./images/shower.jpg'/> Shower </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.bedlinnen ? <p><img src='./images/bed.jpg'/> Bed linnen </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.bikeStorage ? <p><img src='./images/bike.jpg'/> Bike storage </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.laundry ? <p><img src='./images/laundry.jpg'/> Laundry machine </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.dryer ? <p><img src='./images/dryer.jpg'/> Drying machine </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.kitchen ? <p><img src='./images/kitchen.jpg'/> Kitchen </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.fridge ? <p><img src='./images/fridge.jpg'/> Fridge </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.microwave ? <p><img src='./images/microwave.jpg'/> Microwave </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.oven ? <p><img src='./images/oven.jpg'/> Oven </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.stove ? <p><img src='./images/stove.jpg'/> Stove </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.vacuumCleaner ? <p><img src='./images/vacuum.jpg'/> Vacuum cleaner </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceBoysOnly ? <p><img src='./images/boy.jpg'/> Only boys </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceGirlsOnly ? <p><img src='./images/girl.jpg'/> Only girls </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceCouples ? <p><img src='./images/couple.jpg'/> Couples allowed </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceInterns ? <p><img src='./images/intern.jpg'/> Interns </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceStudents ? <p><img src='./images/student.jpg'/> Students </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audiencePets ? <p><img src='./images/pet.jpg'/> Pets allowed </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceNonSmokers ? <p><img src='./images/nonsmoker.jpg'/> Non smokers </p> : ""}</div>
                                <div className='new-line'>{this.props.advertisement.audienceDomiciliation ? <p><img src='./images/dommiciliation.jpg'/> Domiciliation possible </p> : ""}</div>
                                
                                
            
                            </div> 
                        </div>

                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>
            
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formHeader">
                                Address
                            </div> 
                        </div>
            
            
                        <div className = "flexBox" >
                            
                            <div className = "cellSpan2 formLabel" >
                                {this.props.advertisement.address?.street}<br/>
                                {this.props.advertisement.address?.district}<br/>
                                {this.props.advertisement.address?.city}<br/>
                                {this.props.advertisement.address?.country}    
                            < /div>                
                        </div>
                         
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>        
                                
                        <div className = "flexBox" >
                            <div className = "cellSpan2 formHeader">
                                Map
                            </div> 
                        </div>                                

                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <MapContainer address={advertisement.address}/>
                            </div> 
                        </div>

            
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>

                        <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <FacebookShareButton
                                url={'https://www.staydler.com/index.html?action=showAdvertisement&metaID='+advertisement?.advertisementId}
                                quote={'Have a look at this room for rent on Staydler.com'}
                                hashtag="#RentRoomsWithStaydler"
                              >
                                <FacebookIcon size={50} round />
                            </FacebookShareButton>
                        </div> 
                        </div>
                                
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>                                 
            
                        {advertisement.userId == user.id ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <button onClick={e => {this.setMode(e, "updateAdd")}}> Update add < /button>    
                            < /div> 
                        </div>
                        :
                        ""
                        }

                        <div className = "flexBox" >
                            <div className = "cellSpan2">

                            </div> 
                        </div>
            
                        {advertisement.userId == user.id ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <button onClick={e => {this.deleteAdd(e, advertisement.advertisementId)}}> delete add < /button>    
                            < /div> 
                        </div>
                        :
                        ""
                        }
            
            
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>

                        {(user.authority == "ADMIN") && (advertisement.verified)  ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                Verified !
                            < /div> 
                        </div>
                        :
                        ""
                        }

                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                        </div>


                        {(user.id < 0) || (user.id != advertisement.userId) ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                {(user.id < 0) ?
                                <button onClick={e => {this.setMode(e, "explainConversation")}}> Send message < /button>
                                :
                                <button onClick={e => {this.showConversationByAddId(e)}}> Send message < /button>
                                }
                                    
                            < /div> 
                        </div>
                        :
                        ""
                        }


            
                        {user.authority == "ADMIN" ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <button onClick={e => {this.updateAdvertisementSlot(e, advertisement.advertisementId, advertisement.active, true)}}> approve add < /button>    
                            < /div> 
                        </div>
                        :
                        ""
                        }

                        {user.authority == "ADMIN" ?
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <textarea id="message" ref="_message"></textarea>
                                <br/>
                                <button onClick={e => {this.sendAdvertisementMessage(e, advertisement.advertisementId)}}> revoke and send message < /button>  
                            </div> 
                        </div>
                        :
                        ""
                        }
            
                        
                
                   
                </div>
            )
                   
        }  else if (mode == "explainAdd") {//TO DO: edit explanation to register and create add
            return (
                <div>
                
                        <div className = "flexBox" >
                            <div className = "cellSpan2">
                                To create an add please log on or register first.   
                            < /div>                
                        </div>
                </div>
                    
                
                )
        }  else {
            return null
        }
    }

}

Advertisement.propTypes = {
    mode: PropTypes.string,    
    advertisement: PropTypes.object,
    user: PropTypes.object,
    setMode: PropTypes.func,
    countUploadedPhotos: PropTypes.func,
    onChangeText: PropTypes.func,
    onChangeAddress: PropTypes.func,
    onChangeCheckbox: PropTypes.func,
    postAdvertisement: PropTypes.func,
    deletePhoto: PropTypes.func,
    updateAdvertisementSlot: PropTypes.func,
    cityList: PropTypes.array,
    countryList: PropTypes.array,
    districtList: PropTypes.array,
    selectedCountry: PropTypes.number,
    selectedCity: PropTypes.number,
    selectedDistrict: PropTypes.number,
    changeCountry: PropTypes.func,
    changeCity: PropTypes.func,
    changeDistrict: PropTypes.func,
    showConversationByAddId: PropTypes.func,
    deleteAdd: PropTypes.func,
    sendAdvertisementMessage: PropTypes.func
}


Advertisement.defaultProps = {    
    setMode: f => f,
    countUploadedPhotos: f => f,
    onChangeText: f => f,
    onChangeAddress: f => f,
    onChangeCheckbox: f => f,
    postAdvertisement: f => f,
    deletePhoto: f => f,
    updateAdvertisementSlot: f => f,
    changeCountry: f => f,
    changeCity: f => f,
    changeDistrict: f => f,
    showConversationByAddId: f => f,
    deleteAdd: f => f,
    sendAdvertisementMessage: f => f
}

export default Advertisement
