import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'

class PhotoSelect extends Component {


    constructor(props) {
        super(props)
        this.readFile = this.readFile.bind(this)
        this.clear = this.clear.bind(this)
        
        this.state = {}
    }
    
    readFile(e, countUploadedPhotos) {
        
        e.preventDefault
        
        //alert(countUploadedPhotos)
        
        let files = e.target.files;
        var countNewPhotos = files.length
        
        if (countUploadedPhotos + countNewPhotos > 5) {
            alert("Maximum of 5 photo´s exceeded. Please remove some photo´s first or add maximum "+(5-countUploadedPhotos)+" new photo´s.")
            clear(null)
        } else if (countUploadedPhotos + countNewPhotos < 1) {
            alert("Please upload at least 1 photo.")       
        }else {
        
            const formData = new FormData();
            

            for (let i = 0; i < files.length; i++) {

                let reader = new FileReader();
                // TO DO: formData.append(files[i].name, files[i])
                formData.append("file", files[i])

            }
            
            this.props.setFormData(formData)

        }
    }
    
    clear(e) {
        //e.preventDefault
        this.refs._file.value = null
    }

    render() {
        
        const {
            countUploadedPhotos
        }  = this.props 
        
        return (
            <div className="formLabel">
            Note: Maximum 5 photo´s in total<br/><br/>
            <input type="file" accept="image/png, image/jpg, image/gif, image/jpeg" name="file" ref="_file" id="file" onChange={e => this.readFile(e, countUploadedPhotos)} multiple/>
            <button onClick={e => this.clear(e)}>Clear</button>
            </div>
        )
    
    }
}


PhotoSelect.propTypes = {
    countUploadedPhotos: PropTypes.number
}


PhotoSelect.defaultProps = {
    

}

export default PhotoSelect
