import {
    Component
} from 'react';
import { Link } from 'react-router-dom';
import '../css/content.css';
import Popup from "./Popup"; 
import PropTypes from 'prop-types'
import {logon, fetchUser, register, startReset, processReset} from '../api/api'


class Logon extends Component {


    constructor(props) {
        super(props)        
        this.setMode = this.setMode.bind(this)
        this.setWarning = this.setWarning.bind(this)
        this.setMessage = this.setMessage.bind(this)
        this.logonClicked = this.logonClicked.bind(this)
        this.register = this.register.bind(this)
        this.startReset = this.startReset.bind(this)
        this.processReset = this.processReset.bind(this)
        this.validateEmail = this.validateEmail.bind(this)
        this.validatePassword = this.validatePassword.bind(this)
        this.validatePasswordConfirm = this.validatePasswordConfirm.bind(this)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.onChangePreference = this.onChangePreference.bind(this)
        this.deleteProfileMail = this.deleteProfileMail.bind(this)
        this.togglePopup = this.togglePopup.bind(this)
        this.modifyProfile = this.modifyProfile.bind(this)
        this.modifyUser = this.modifyUser.bind(this)
        this.showAddList = this.showAddList.bind(this)
        this.createAdvertisement = this.createAdvertisement.bind(this)
        
        
        
        this.state = { 
            popup:false
        }
        
    }
    
    showAddList(e,user, searchMode, rowStart) {
        e.preventDefault()        
        this.props.showAddList(user, searchMode, rowStart)
        
    }
    
    
    createAdvertisement(e) {
        e.preventDefault()
        this.props.createAdvertisement()
    }
    
    togglePopup() {
        let state = {
            ...this.state,
            popup:!this.state.popup
        }
        
        console.log(this.state)
        
        this.setState(state)
    }
    
    selectLanguage(lang) {
        this.props.selectLanguage(lang)
    }
    
    setMode(e, mode) {
        if (e!=null) {
        e.preventDefault()
        }
        
        this.props.setMode(mode)

    }
    
    deleteProfileMail(e, userID) {
        e.preventDefault()
        if (confirm("Are you certain you wish to delete your profile ? Deleting the profile will also remove all advertisements and conversations related to this profile. This action cannot be undone.") == true) {
            this.props.deleteProfileMail(userID)
        }
        
    }

    logonClicked(e) {
        e.preventDefault()        
        const userName = this.refs._userName.value
        const passWord = this.refs._passWord.value        
        var validEmail = this.validateEmail(userName)
        if (!validEmail) {
            this.setWarning("Invalid email")
        } else {
            this.setWarning("")
                        
            logon(userName, passWord).then(result => {
                
                if (!result.success) {
                    this.setWarning("logon failed, please try again.") 
                } else {
                   
                //CORS    
                sessionStorage.setItem("token", result.authorization)    
                    
                fetchUser().then(result=> {
                    
                    if (result.success) {
                        
                        this.props.logonSuccess(result)
                        this.selectLanguage("NA")
                        
                    } else {
                        this.setWarning("logon failed, please try again.") 
                    }
                    
                    }).catch(error => this.setMessage("Error at Logon / fetchUser"))
                }
                }).catch(error => {console.error(error);this.setMessage("Error at Logon")})
            }
    }
    
    onChangePreference(e) {
            
            e.preventDefault
            this.props.onChangePreference(e)
            
    }
    

    modifyProfile(e) {
        e.preventDefault()
        const firstName = this.refs._firstName.value  
        const lastName = this.refs._lastName.value
        const phoneNumber = this.refs._phoneNumber.value
        
        
         if (firstName.length == 0) {
                this.setWarning("Please enter your first name.")
            } else if (lastName.length == 0) {
                this.setWarning("Please enter your last name.")
            } else if (phoneNumber.length == 0) {
                this.setWarning("Please enter your phone number.")
            } else {
                 this.props.modifyProfile(firstName, lastName, phoneNumber)
            }
    }
    
    
    modifyUser(e) {
        e.preventDefault()
        const userName = this.refs._userName.value
        
        var validEmail = this.validateEmail(userName)
        if (!validEmail) {
            this.setWarning("Invalid email")
        } else {
            this.props.modifyUser(userName) 
        }
    }
    
    register(e) {
        e.preventDefault()
        const authority = this.refs._lessor.checked ? this.refs._lessor.value : this.refs._tenant.value
        
        
        const userName = this.refs._userName.value
        const passWord = this.refs._passWord.value  
        const confirmPassWord = this.refs._confirmPassWord.value  
        const firstName = this.refs._firstName.value  
        const lastName = this.refs._lastName.value
        const phoneNumber = this.refs._phoneNumber.value
        const termsAgreed = this.refs._terms.checked
        
        var validEmail = this.validateEmail(userName)
        if (!validEmail) {
            this.setWarning("Invalid email")
        } else {
            
            if (firstName.length == 0) {
                this.setWarning("Please enter your first name.")
            } else if (lastName.length == 0) {
                this.setWarning("Please enter your last name.")
            } else if (phoneNumber.length == 0) {
                this.setWarning("Please enter your phone number.")
            } else {
            
            
                var validPassWord = this.validatePassword(passWord)
                if (!validPassWord) {
                    this.setWarning("Password must be 8 characters long and contain at least one uppercase, one lowercase and one special character.")
                } else {
                    validPassWord = this.validatePasswordConfirm(passWord, confirmPassWord)
                    if (!validPassWord) {
                        this.setWarning("Password and confirmation password must be the same.")
                    } else {
                        if (!termsAgreed) {
                            this.setWarning("Please accept the terms of usage.")
                        } else {
                        
                            register(userName, passWord, firstName, lastName, phoneNumber, authority).then(result => {
                                if (result.success) {
                                    this.setWarning("We have sent an e-mail. Click on the link in it to confirm your registration") 
                                }
                            }).catch(error => this.setMessage("Error at Logon / register"))
                        }
                    }
                }
            }
        }
        
    }
    
     startReset(e) {
        e.preventDefault()        
        const userName = this.refs._userName.value  
        
            
        var validEmail = this.validateEmail(userName)
        if (!validEmail) {
            this.setWarning("Invalid email")
        } else {
              startReset(userName).then(reponse => 
                    {
                    if (!reponse) {
                        this.setWarning("Unregistered e-mail, please try again.")
                    } else {
                        this.setWarning("Please verify your e-mail.")    
                    }
                }).catch(error => this.setMessage("Error at Logon / startReset"))  
        }
        
        
    }
    
    processReset(e) {
        e.preventDefault()        
        const passWord = this.refs._passWord.value  
        const confirmPassWord = this.refs._confirmPassWord.value
        const resetToken = this.refs._resetToken.value 
        
        var validPassWord = this.validatePassword(passWord)
            if (!validPassWord) {
                this.setWarning("Password must be 8 characters long and contain at least one uppercase, one lowercase and one special character.")
            } else {
                validPassWord = this.validatePasswordConfirm(passWord, confirmPassWord)
                if (!validPassWord) {
                    this.setWarning("Password and confirmation password must be the same.")
                } else {
                    processReset(resetToken, passWord).then(result => {
                    this.setWarning("Your password was reset. Please log on again.")
                    }).catch(error => this.setMessage("Error at Logon / processReset"))       
                }
        }
    }

    setWarning(warning) {

        this.props.setWarning(warning)
        

    }
    
    
    
    validateEmail(email) {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return true;
        } else {
            return false;
        }

    }
    
    validatePasswordConfirm(passWord1, passWord2) {        
        if (passWord1 == passWord2) {
            return true
        } else return false
    }
    
    validatePassword(passWord1) {        
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        if(strongPassword.test(passWord1)) {
            return true
        } else {
            return false
        }
        
    }    
    
    //TO DO: ok with setMode
    componentDidMount(){
        
                
        if (this.props.confirmation == "register") {
            this.setMessage("Your account has been confirmed. Please log on.")
        } else if (this.props.confirmation == "startreset") {
            this.setMode(null,"startreset")
        } else if (this.props.confirmation == "processreset") {
            this.setMode(null,"processreset")
        } else if (this.props.confirmation == "reset") {
            this.setMessage("Your password has been reset. Please log on.")
        }
    
    }
    
    
    
       
    setMessage(message) {
        this.props.setMessage(message)  
        
    }
    


    render() {
        
        const {
            mode,            
            message,
            warning,
            user,            
            confirmation,
            resetToken
        } = this.props              
        
        
        if (mode == "logon") {

            return ( <div>
                <
                form onSubmit = {
                    this.logonClicked
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 badInput"> {
                    warning
                } < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > e-mail: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_userName" / > < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > passWord: < /div> <
                div className = "cellRight50" > < input type = "password"
                ref = "_passWord" / > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellSpan2 "//TO DO: align
                > Forgot password ? <a href="javascript:void(0)" onClick={e => this.setMode(e, "startreset")}>reset password</a> < /div> <
                /div>  
                <
                div className = "cellSpan2"
                > 
                <input type="hidden" ref="_action" />
                <input type="hidden" ref="_metaID" />
                < /div> 
                <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > < button > Log on < /button></div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form> 
                </div>


            )
        } else if (mode == "subscribe") {

            return ( 
               <div>
                <
                form onSubmit = {
                    this.register
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 badInput"> {
                    warning
                } < /div> <
                /div> 
                
                <div className = "flexBox" >
                <div className = "cellLeft50">
                    <input id="tenant" name="authority" type="radio" value="LESSOR" ref="_tenant" checked /><label for="tenant">I am looking for a room</label>
                </div>
                <div className = "cellRight50">
                    <input id="lessor" name="authority" type="radio" value="TENANT" ref="_lessor" /><label for="lessor">I wish to publish a room</label>
                </div>
                </div>    
                    
                    <
                div className = "flexBox" >
                    {this.state.popup ? <Popup togglePopup={this.togglePopup} /> : null} 
                
                <
                div className = "cellLeft50 formLabel" > e-mail: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_userName" / > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > first name: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_firstName" / > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > last name: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_lastName" / > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > phone number: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_phoneNumber" / > < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > passWord: < /div> <
                div className = "cellRight50" > < input type = "password"
                ref = "_passWord" / > < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > confirm passWord: < /div> <
                div className = "cellRight50" > < input type = "password"
                ref = "_confirmPassWord" / > < /div> <
                /div>

                <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <input type="checkbox" id="terms" value="terms" ref="_terms" />                                   
                                I accept the <a href="javascript:void(0)" onClick={this.togglePopup}>terms of use</a>       
                        </div> 
                    </div>

                    
                <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > < button > Register < /button></div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form> 
                </div>
            )

        } else if (mode == "modifyProfile") {

            return ( 
                <div>
                <
                form onSubmit = {
                    this.modifyProfile
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 badInput"> {
                    warning
                } < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > first name: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_firstName"  defaultValue={user.firstname}/ > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > last name: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_lastName"  defaultValue={user.lastname}/ > < /div> <
                /div><
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > phone number: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_phoneNumber"  defaultValue={user.phone}/ > < /div> <
                /div> 
                                 
                <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > 
                    < button > Save < /button>
                </div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form> 
                </div>
            )

        }  else if (mode == "modifyUser") {

            return ( 
               <div>
                <
                form onSubmit = {
                    this.modifyUser
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> 
                <
                div className = "flexBox" >
                    {this.state.popup ? <Popup togglePopup={this.togglePopup} /> : null} 
                <
                div className = "cellLeft50 formLabel" > new e-mail: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_userName" / > < /div> <
                /div>
                                 
                <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > 
                    < button > Modify < /button>
                </div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form>
                </div>
            )

        } else if (mode == "preferences") {

            
            
            return ( <div>
                    <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="emailMessageReceived" value="emailMessageReceived" onChange={e => {this.onChangePreference(e)}} defaultChecked={this.props.emailMessageReceived == true}/> E-mail when you receive a message       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="emailAddApproved" value="emailAddApproved" onChange={e => {this.onChangePreference(e)}} defaultChecked={this.props.emailAddApproved == true}/> E-mail when your adverstisement is approved       
                        </div> 
                    </div>
                    <div className = "flexBox" >
                        <div className = "cellSpan2 formLabel">
                            <input type="checkbox" id="emailAddExpired" value="emailAddExpired" onChange={e => {this.onChangePreference(e)}} defaultChecked={this.props.emailAddExpired == true}/> E-mail when your advertisement expires      
                        </div> 
                    </div>
                    
                    <div className = "flexBox" >
                            <div className = "cellSpan2">
                                &nbsp;
                            </div> 
                    </div>
            
                    <div className = "flexBox" >
                            <div className = "cellSpan2">
                                <button onClick={e => {this.deleteProfileMail(e, user.id)}}> delete profile < /button>    
                            < /div> 
                    </div>
                    
                
                </div>
            )

        } else if (mode == "startreset") {
            return ( <div>
                <
                form onSubmit = {
                    this.startReset
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 badInput"> {
                    warning
                } < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > e-mail: < /div> <
                div className = "cellRight50" > < input type = "text"
                ref = "_userName" / > < /div> <
                /div>  <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > < button > Submit < /button></div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form> 
                </div>
            )

        } else if (mode == "processreset") {
            return ( <div>
                <
                form onSubmit = {
                    this.processReset
                } >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 badInput"> {
                    warning
                } < /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > Password: < /div> <
                div className = "cellRight50" > < input type = "password"
                ref = "_passWord" / > < /div> <
                /div>                             <
                div className = "flexBox" >
                <
                div className = "cellLeft50 formLabel" > confirm passWord: < /div> <
                div className = "cellRight50" > < input type = "password"
                ref = "_confirmPassWord" / >
                <input type="hidden" ref = "_resetToken" value = {resetToken} />< /div> <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellLeft50" > < button > Submit < /button></div >
                <
                div className = "cellRight50" > < /div> <
                /div> <
                /form>
                </div>
            )

        } else if (mode == "message") {
            return ( <div>
                <
                form >
                <
                div className = "flexBox" >
                <
                div className = "cellSpan2 alignRight" > < a href = {
                    undefined
                }
                onClick = {
                    e => this.showAddList(e, null, "searchAndResults", -1)
                } > [X] < /a></div >
                <
                /div> <
                div className = "flexBox" >
                <
                div className = "cellSpan2 formLabel" > {
                    message
                } < /div> <
                /div>                             <
                /form> 
                </div>
            )

        } else {
            return null
        }




    }

}


Logon.propTypes = {
    mode: PropTypes.string,
    warning: PropTypes.string,
    user: PropTypes.object,
    confirmation: PropTypes.string,
    resetToken: PropTypes.string,
    setMode: PropTypes.func,
    logon: PropTypes.func,
    logonSuccess: PropTypes.func,
    register: PropTypes.func,
    setMessage: PropTypes.func,
    selectLanguage: PropTypes.func,
    emailMessageReceived: PropTypes.bool,
    emailAddExpired: PropTypes.bool,
    onChangePreference: PropTypes.func,
    deleteProfileMail: PropTypes.func,
    modifyProfile:  PropTypes.func,
    modifyUser:  PropTypes.func,
    showAddList: PropTypes.func,
    createAdvertisement: PropTypes.func
}


Logon.defaultProps = {
    setMode: f => f,
    logon: f => f,
    logonSuccess: f => f,
    register: f => f,
    setMessage: f => f,
    selectLanguage: f => f,
    onChangePreference: f => f,
    deleteProfileMail: f => f,
    modifyProfile: f => f,
    modifyUser: f => f,
    showAddList: f => f,
    createAdvertisement: f => f
}

export default Logon
