//TO DO:  work with one mode i.s.o. logonMode and advertisementMode

import {
    Component
} from 'react';
import Language from './Language'
import Menu from './Menu'
import Logon from './Logon'
import Tiles from './Tiles'
import Advertisement from './Advertisement'
import AdvertisementList from './AdvertisementList'
import Conversation from './Conversation'
import Display from './Display'
import '../css/content.css';
import {logoff,langPreference,createAdvertisementSlot,updateAdvertisementSlot,postAdd,updateAdd,loadPreferences,setPreferences,loadAdd,loadAddList,loadCountries,loadCities,loadDistricts,uploadPhotos,removePhoto,loadConversationByAddId,loadConversationById,getBriefConversations,sendMessage,getCredits,buyCredits,unsubscribeMail,deleteAdd,deleteProfileMail,deleteProfile,sendAdvertisementMessage,modifyProfile,modifyUser,confirmModifyUser} from '../api/api'
import Checkout from './Checkout'




class Main extends Component {

    constructor(props) {
        super(props)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.setMessage = this.setMessage.bind(this)        
        this.setWarning = this.setWarning.bind(this)        
        this.logonSuccess = this.logonSuccess.bind(this)
        this.isLoggedOn = this.isLoggedOn.bind(this)
        this.logoff = this.logoff.bind(this)
        this.setMode = this.setMode.bind(this)
        this.countUploadedPhotos = this.countUploadedPhotos.bind(this)
        this.onChangeText = this.onChangeText.bind(this)
        this.onChangeAddress = this.onChangeAddress.bind(this)
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.onChangePreference = this.onChangePreference.bind(this)
        this.postAdvertisement = this.postAdvertisement.bind(this)
        this.deletePhoto = this.deletePhoto.bind(this)
        this.viewAdd = this.viewAdd.bind(this)
        this.showAddList = this.showAddList.bind(this)
        this.updateAdvertisementSlot = this.updateAdvertisementSlot.bind(this)
        this.loadCountries = this.loadCountries.bind(this)
        this.loadCities = this.loadCities.bind(this)
        this.loadDistricts = this.loadDistricts.bind(this)
        this.loadDistrictsAndPush = this.loadDistrictsAndPush.bind(this)
        this.createAdvertisement = this.createAdvertisement.bind(this)
        this.changeCountry = this.changeCountry.bind(this)
        this.changeCity = this.changeCity.bind(this)
        this.changeDistrict = this.changeDistrict.bind(this)
        this.changeSearchCity = this.changeSearchCity.bind(this)
        this.pushDistricts = this.pushDistricts.bind(this)
        this.showPreferences = this.showPreferences.bind(this)
        this.setPreferences = this.setPreferences.bind(this)
        this.showConversation = this.showConversation.bind(this)
        this.showConversationByAddId = this.showConversationByAddId.bind(this)
        this.showConversationById = this.showConversationById.bind(this)
        this.addMessage = this.addMessage.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.getCredits = this.getCredits.bind(this)
        this.buyCredits = this.buyCredits.bind(this)
        this.unsubscribeMail = this.unsubscribeMail.bind(this)
        this.deleteAdd = this.deleteAdd.bind(this)
        this.deleteProfileMail = this.deleteProfileMail.bind(this)
        this.deleteProfile = this.deleteProfile.bind(this)
        this.sendAdvertisementMessage = this.sendAdvertisementMessage.bind(this)
        this.showModifyProfile = this.showModifyProfile.bind(this)
        this.modifyProfile = this.modifyProfile.bind(this)
        this.showModifyUser = this.showModifyUser.bind(this)
        this.modifyUser = this.modifyUser.bind(this)
        this.confirmModifyUser = this.confirmModifyUser.bind(this)
        
        this.state = {
            user: {
                success: false,
                id: -1,
                authority: "",
                firstname: "",
                lastname: "",
                username: "",
                enabled: false,
                phone:""
                
            },
            searchUser:0,
            selectedCountry:1,
            selectedCity:1,
            selectedCityLatitude:0,
            selectedCityLongitude:0,
            displayUrl:"https://www.staydler.com/IStay_Project/istayPhotos/Home.jpg",
            selectedDistricts:[[10]],
            countryList:[],
            cityList:[],
            districtList: [],
            language: "en",
            emailMessageReceived: false,
            emailAddApproved: false,
            emailAddExpired: false,
            mode: "welcome",
            searchMode: "",
            message: "",
            warning: "",
            "advertisement": {
              "advertisementId": -1,
              "minimumMonths": 0,
              "dateAvailableFrom": "29-09-2019",
              "dateAvailableTo": "29-12-2022",
              "surfaceArea": 0,
              "rentFee": 0,
              "rentCharges": 0,
              "briefDescription": "",
              "detailedDescription": "",
              "accomodationType": "co-housing",
              "audienceInterns": false,
              "audienceStudents": false,
              "audienceGirlsOnly": false,
              "audienceBoysOnly": false,
              "audienceNonSmokers": false,
              "audiencePets": false,
              "audienceCouples": false,
              "audienceDomiciliation": false,
              "wifi": false,
              "kitchen": false,
              "microwave": false,
              "stove": false,
              "fridge": false,
              "oven": false,
              "bedlinnen": false,
              "bathroom": false,
              "shower": false,
              "laundry": false,
              "dryer": false,
              "vacuumCleaner": false,
              "ironingBoard": false,
              "bikeStorage": false,
              "address": {
                "street": "",
                "streetNumber": "",
                "districtId": ""
              },
              "photos": [
              ],  
              "active": true,
              "verified": false
            },
            addList:{
                addList:[{"success":false}]
            },
            conversation:{
                conversationId:-1,
                advertisementId:-1,
                advertisementTitle:"",
                messages:[]
            },
            briefConversationList: {
                success: false,
                conversations: [[]]
            },
            credits:0,
            rowStart:0,
            rowBatchCount:2
            
        }
    }

    selectLanguage(lang) {
        
        if (this.isLoggedOn()) {
            
            langPreference(lang).then(result=> {
                lang = result.acceptLanguage
                
                let state = {
                    ...this.state,
                    "language": lang
                }
                this.setState(state, () => {console.log(this.state)})        
            })
        } else { 
          
            if (lang == "NA") {
                lang = "en"
            }

            let state = {
                ...this.state,
                "language": lang
            }

            this.setState(state, () => {console.log(this.state)})        
        }
        
    }
    
    isLoggedOn() {
        return this.state.user.id >= 0;
    }
    
    setMode(mode) {
        
        
        if (mode == "logon" && this.isLoggedOn()) {//check if user exists, in which case we are already logged on
            this.setMode("welcomeLoggedOn");
        } else {
            
            if (mode == "updateAdd") {// either from createAdvertisement() or Advertisement:mode="showAdd"
                
                this.loadCountries()
                this.loadCities(this.state.selectedCountry)
                this.loadDistricts(this.state.selectedCity)
                
            } else if (mode=="showAddList") {
                if (this.state.searchMode == "searchAndResults" || this.state.searchMode == "searchMap") {
                    
                    this.loadCountries()
                    this.loadCities(this.state.selectedCountry)
                    this.loadDistricts(this.state.selectedCity)
                    
                    
                }
            } else if (mode=="credits") {
                    this.getCredits()
            }                 
            
            let state = {
                ...this.state,
                "warning": "",
                "mode": mode
                
            }
        
            //alert("setting state: "+this.state.mode)
            this.setState(state, () => {console.log(this.state)})
            
        }
    }
    
    
    
    
    changeCountry(e) {
        
            
        this.loadCities(e.target.value)
            
        let state = {
            ...state,
            selectedCountry: parseInt(e.target.value),
            districtList: []
        }
        
        this.setState(state, () => {console.log(this.state);})
        
        
        
        
        
    }
    
    changeCity(e) {
        
        
        
        let state = {
            ...state,
            districtList: []
        }
        
        this.setState(state, () => {console.log(this.state)})
        
        this.loadDistricts(e.target.value)
        
          
        state = {
            ...state,           
            selectedCity: parseInt(e.target.value)
        }
        
        this.setState(state, () => {console.log(this.state)})
        
    }
    
    changeSearchCity(e, searchUser) {
        
                
        //alert("change search city: "+e.target.value)
        
        /*let state = {
            ...state,
            districtList: []
        }
        
        
        
        this.setState(state, () => {console.log(this.state)})*/
        
        var city = this.state.cityList.filter(({ cityId }) => cityId === parseInt(e.target.value))
        //console.log("CITY: "+JSON.stringify(selectedCity[0].cityLatitude))
        
        let state = {
            ...state,              
            selectedCity: parseInt(e.target.value),
            selectedCityLatitude:city[0].cityLatitude,
            selectedCityLongitude:city[0].cityLongitude,
            displayUrl:city[0].displayUrl
        }
        
        
        
        this.setState(state, () => {console.log(this.state);})
        
        
        this.loadDistrictsAndPush(e.target.value, searchUser)
        
        
        
        
        
        
        
    }
    
    pushDistricts(searchUser) {
        
        var prevSearchMode = this.state.searchMode
        
        let selectedDistricts = []
        this.state.districtList.map((district) => {
            selectedDistricts.push([district.districtId])
        })
        
        let state = {
            ...state,           
            selectedDistricts: selectedDistricts
        }
        
        //console.log("selectedDistricts: "+state.selectedDistricts)
        
        //if (prevSearchMode == "searchMap") {// We want to remount <MapSearchContainer>, so we unmount it first
            //this.setMessage("Loading ...")
            //this.setSearchMode("searchAndResults")
        //}
        
        
        if (prevSearchMode == "loading") {
            prevSearchMode = "searchMap"
        }
        
        this.setState(state, () => {console.log(this.state);this.showAddList(searchUser, prevSearchMode, 0);})
        
        
        
    }
    
    changeDistrict(e) {
         
        //alert("change district: "+e.target.value)
        
        let state = {
            ...state,
            selectedDistricts: [[parseInt(e.target.value)]],
            "advertisement": {
                ...this.state.advertisement,
                "address": {
                    ...this.state.advertisement.address,
                    "districtId": parseInt(e.target.value)
                }    
            }
        }
        
        this.setState(state, () => {console.log(this.state)})
        
    }
    
    
    createAdvertisement() {
                let state = {
                                user: {
                                    ...this.state.user

                                },
                                searchUser:this.state.searchUser,
                                selectedCountry:1,
                                selectedCity:1,
                                selectedCityLatitude:0,
                                selectedCityLongitude:0,
                                displayUrl:this.state.displayUrl,
                                selectedDistricts:[[10]],
                                countryList:[],
                                cityList:[],
                                districtList: [],            
                                language: this.state.language,
                                emailMessageReceived: this.state.emailMessageReceived,
                                emailAddApproved: this.state.emailAddApproved,
                                emailAddExpired: this.state.emailAddExpired,            
                                mode: "welcome",
                                searchMode: "",
                                message: "",
                                warning: "",
                                "advertisement": {
                                  "advertisementId": -1,
                                  "minimumMonths": 0,
                                  "dateAvailableFrom": "29-09-2019",
                                  "dateAvailableTo": "29-12-2022",
                                  "surfaceArea": 0,
                                  "rentFee": 0,
                                  "rentCharges": 0,
                                  "briefDescription": "",
                                  "detailedDescription": "",
                                  "accomodationType": "co-housing",
                                  "audienceInterns": false,
                                  "audienceStudents": false,
                                  "audienceGirlsOnly": false,
                                  "audienceBoysOnly": false,
                                  "audienceNonSmokers": false,
                                  "audiencePets": false,
                                  "audienceCouples": false,
                                  "audienceDomiciliation": false,
                                  "wifi": false,
                                  "kitchen": false,
                                  "microwave": false,
                                  "stove": false,
                                  "fridge": false,
                                  "oven": false,
                                  "bedlinnen": false,
                                  "bathroom": false,
                                  "shower": false,
                                  "laundry": false,
                                  "dryer": false,
                                  "vacuumCleaner": false,
                                  "ironingBoard": false,
                                  "bikeStorage": false,
                                  "address": {
                                    "street": "",
                                    "streetNumber": "",
                                    "districtId": ""
                                  },
                                  "photos": [
                                  ],  
                                  "active": true,
                                  "verified": false
                                },
                                addList:{
                                    addList:[{"success":false}]
                                },
                                conversation:{
                                    ...this.state.conversation
                                },
                                briefConversationList: {
                                    ...this.state.briefConversationList
                                },
                                credits:this.state.credits,
                                rowStart:this.state.rowStart,
                                rowBatchCount:this.state.rowBatchCount

                            }

                        if (this.state.user.id >= 0) {
                            this.setState(state, () => {console.log(this.state);this.setMode("updateAdd");});
                        } else {
                            this.setState(state, () => {console.log(this.state);this.setMode("explainAdd");});
                        }
        
    }
    
    
    logoff() {
        
        logoff().then(result=> {
                        let state = {
                                user: {
                                    success: false,
                                    id: -1,
                                    authority: "",
                                    firstname: "",
                                    lastname: "",
                                    username: "",
                                    enabled: false,
                                    phone:""

                                },
                                searchUser:0,
                                selectedCountry:1,
                                selectedCity:1,
                                selectedCityLatitude:0,
                                selectedCityLongitude:0,
                                displayUrl:"https://www.staydler.com/IStay_Project/istayPhotos/Home.jpg",
                                selectedDistricts:[[10]],
                                countryList:[],
                                cityList:[],
                                districtList: [],            
                                language: "en",
                                emailMessageReceived: false,
                                emailAddApproved: false,
                                emailAddExpired: false,            
                                mode: "welcome",
                                searchMode: "",
                                message: "",
                                warning: "",
                                "advertisement": {
                                  "advertisementId": -1,
                                  "minimumMonths": 0,
                                  "dateAvailableFrom": "29-09-2019",
                                  "dateAvailableTo": "29-12-2022",
                                  "surfaceArea": 0,
                                  "rentFee": 0,
                                  "rentCharges": 0,
                                  "briefDescription": "",
                                  "detailedDescription": "",
                                  "accomodationType": "co-housing",
                                  "audienceInterns": false,
                                  "audienceStudents": false,
                                  "audienceGirlsOnly": false,
                                  "audienceBoysOnly": false,
                                  "audienceNonSmokers": false,
                                  "audiencePets": false,
                                  "audienceCouples": false,
                                  "audienceDomiciliation": false,
                                  "wifi": false,
                                  "kitchen": false,
                                  "microwave": false,
                                  "stove": false,
                                  "fridge": false,
                                  "oven": false,
                                  "bedlinnen": false,
                                  "bathroom": false,
                                  "shower": false,
                                  "laundry": false,
                                  "dryer": false,
                                  "vacuumCleaner": false,
                                  "ironingBoard": false,
                                  "bikeStorage": false,
                                  "address": {
                                    "street": "",
                                    "streetNumber": "",
                                    "districtId": ""
                                  },
                                  "photos": [
                                  ],  
                                  "active": true,
                                  "verified": false
                                },
                                addList:{
                                    addList:[{"success":false}]
                                },
                                conversation:{
                                    conversationId:-1,
                                    advertisementId:-1,
                                    advertisementTitle:"",
                                    messages:[]
                                },
                                briefConversationList: {
                                    success: false,
                                    conversations: [[]]
                                },
                                credits:0,
                                rowStart:0,
                                rowBatchCount:2

                            }

                        this.setState(state, () => {console.log(this.state);this.showAddList(null, "searchAndResults", -1)});


                        }).catch(error => this.setMessage("Error at logoff"))

    }
    
    setMessage(message) {
        
        let state = {
                ...this.state,
                "message": message
            }
        
        
        
        this.setState(state, () => {this.setMode("message")})
        
    }
    
    setWarning(warning) {
        
        let state = {
                ...this.state,
                "warning": warning
            }
        
        
        
        this.setState(state)
        
    }    
    
    logonSuccess(user) {
        
        const querystring=window.location.search;
        const urlParams=new URLSearchParams(querystring);
        const action=urlParams.get('action');
        const metaID=urlParams.get('metaID');
       
        
        if (action=="showConversation" && metaID!=null) {
            
            getBriefConversations().then(response => {
                 
                if (!response.success) {
                        this.setMessage(response.message) 
                    } else {

                                let state = {
                                    ...this.state,
                                    "user": user,
                                    "briefConversationList" : {
                                    ...response
                                    }
                                }

                                this.setState(state, () => {console.log(this.state);this.showConversationById(metaID);})
                }
            }).catch(error => this.setMessage("Error at logonSuccess / showConversation / getBriefConversations"))
            
            
        } else if (action=="showAdvertisement" && metaID!=null) {
                   
               getBriefConversations().then(response => {
                   
                   if (!response.success) {
                        this.setMessage(response.message) 
                    } else {

                                let state = {
                                    ...this.state,
                                    "user": user,
                                    "briefConversationList" : {
                                    ...response
                                    }
                                }

                                this.setState(state, () => {console.log(this.state);this.viewAdd(metaID);})
                    }
               }).catch(error => this.setMessage("Error at logonSuccess / showAdvertisement / getBriefConversations"))
                   
                   
        }  else {
        
            getBriefConversations().then(response => {
                    
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {

                                let state = {
                                    ...this.state,
                                    "user": user,
                                    "briefConversationList" : {
                                    ...response
                                    }
                                }

                                this.setState(state, () => {console.log(this.state);this.setMode("welcomeLoggedOn");})
                        
                    }
                }).catch(error => this.setMessage("Error at logonSuccess / getBriefConversations"))
        }

    }
    
    countUploadedPhotos() {
      return this.state.advertisement.photos?.length
    }
    
    onChangeText(e) {
        
        let propertyValue = e.target.value
        
        if (propertyValue == "true") {
            propertyValue = true
        }
        
        if (propertyValue == "false") {
            propertyValue = false
        }
        
        
        let state = {
            ...this.state,
            "advertisement" : {
                ...this.state.advertisement,
                [e.target.id]: propertyValue     
            }
            
        }
        
        this.setState(state, () => {console.log(this.state)}) 
        
    }
    
    onChangeAddress(address) {
       
        let state = {
            ...this.state,
            "advertisement" : {
                ...this.state.advertisement,
                "address" : {
                    ...this.state.advertisement.address,
                    ...address     
                }
                    
            }
            
        }
        
        this.setState(state, () => {console.log(this.state)}) 
    }
    
    
    onChangeCheckbox(e) {
            
            let state = {
                ...this.state,
                "advertisement" : {
                    ...this.state.advertisement,
                    [e.target.id]: e.target.checked     
                }

            }

            this.setState(state, () => {console.log(this.state)})
        
    }
    
    onChangePreference(e) {
            
            let state = {
                ...this.state,
                [e.target.id]: e.target.checked     
            }
            
            
            //this.setState(state, () => {console.log(this.state)})
            this.setState(state, () => this.setPreferences())
            
            

            
        
    }
    
    setPreferences() {
        setPreferences(this.state.emailMessageReceived, this.state.emailAddApproved, this.state.emailAddExpired).then(response => {

                            //alert("got "+response.emailMessageReceived+","+response.emailAddExpired)
            
                            let state = {
                                    ...this.state,
                                    emailMessageReceived: response.emailMessageReceived,
                                    emailAddApproved: response.emailAddApproved,
                                    emailAddExpired: response.emailAddExpired            
                                }

                            this.setState(state, () => {console.log(this.state);this.setMode("preferences");})
            }).catch(error => this.setMessage("Error at setPreferences"))
    }
    
    postAdvertisement(formData) {
        
        
        let state = {
            ...state,
            "advertisement": {
                ...this.state.advertisement,
                "address": {
                    ...this.state.advertisement.address,
                    "districtId": this.state.selectedDistricts[0][0]
                }    
            }
        }
        
        this.setState(state, () => {console.log(this.state)})
        
        
            
        
        if (this.state.advertisement.advertisementId < 0) {
                createAdvertisementSlot().then(response1 => {
                    
                    
                    
                    postAdd(this.state.advertisement, this.state.language).then(response2 => {

                        if (!response2.success) {
                            this.setMessage(response.message) 
                        } else {
                        
                        
                                let state = {
                                ...this.state,
                                "advertisement" : {
                                        ...response2

                                    }
                                }

                                this.setState(state, () => {console.log(this.state)})


                                var success = true

                                if (formData != null) {
                                    uploadPhotos(this.state.advertisement.advertisementId, formData, this.state.language).then(response3 => {

                                        
                                    if (!response3.success) {
                                        success = false
                                        alert(response3.message)
                                        //this.setMessage(response3.message) 
                                    } else {   
                                        let state = {
                                            ...this.state,
                                            "advertisement" : {
                                                ...response3,
                                                "verified": false
                                            }
                                        }

                                        if (success) {
                                            this.setState(state, () => {console.log(this.state);this.viewAdd(this.state.advertisement.advertisementId);})
                                        }

                                    }



                                    }).catch(error => alert("Maximum 2MB per photo (10MB for 5 photo´s))"))
                                }

                                this.viewAdd(this.state.advertisement.advertisementId)
                        
                        
                        }
                    }).catch(error => alert("Error at postAdvertisement)"))
                        
                }).catch(error => this.setMessage("Error at postAdvertisement / createAdvertisementSlot"))
                                               
                                               
        } else {
            
                //TO DO: test isVerified = false when new photos are uploaded
                if (formData != null) {
                      
                    
                    uploadPhotos(this.state.advertisement.advertisementId, formData, this.state.language).then(response => {

                        
                        if (!response.success) {
                                alert(response.message)
                                //this.setMessage(response.message) 
                        } else {
                        
                                    let state = {
                                        ...this.state,
                                        "advertisement" : {
                                            ...response,
                                            "verified": false
                                        }
                                    }

                                    this.setState(state, () => {

                                        updateAdd(this.state.advertisement, this.state.language).then(response => {

                                        let state = {
                                            ...this.state,
                                            "advertisement" : {
                                            ...response
                                            }
                                        }

                                        this.setState(state, () => {console.log(this.state);this.viewAdd(this.state.advertisement.advertisementId);})

                                        })
                                    })



                                }
                        }).catch(error => alert("Photo´s file size maximum 2MB per photo (10 MB for 5 photo´s)"))

                    
                } else {
                
                    updateAdd(this.state.advertisement, this.state.language).then(response => {
                        
                        if (!response.success) {
                            this.setMessage(response.message) 
                        } else {
                       
                            let state = {
                                ...this.state,
                                "advertisement" : {
                                ...response
                                }
                            }

                            this.setState(state, () => {console.log(this.state);this.viewAdd(this.state.advertisement.advertisementId);})
                            
                        }
                    }).catch(error => this.setMessage("Error at postAdvertisement / updateAdd"))
                }
        
        }
    }
     
    deletePhoto(photoURI) {
        
        removePhoto(this.state.advertisement.advertisementId, photoURI,this.state.language).then(response => {

            
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
                        
                            let state = {
                                ...this.state,
                                "advertisement" : {
                                ...response
                                }
                            }

                            this.setState(state, () => {console.log(this.state)})

                            

                    }
                }).catch(error => this.setMessage("Error at removePhoto"))
    }
    
    deleteAdd(advertisementID) {
        
        deleteAdd(advertisementID).then(response => {

            
                    if (!response) {
                        this.setMessage("Failed to delete advertisement") 
                    } else {

                            getBriefConversations().then(response => {
                   
                                    if (!response.success) {
                                        this.setMessage(response.message) 
                                    } else {

                                                let state = {
                                                    ...this.state,
                                                    "advertisement": {
                                                          "advertisementId": -1,
                                                          "minimumMonths": 0,
                                                          "dateAvailableFrom": "29-09-2019",
                                                          "dateAvailableTo": "29-12-2022",
                                                          "surfaceArea": 0,
                                                          "rentFee": 0,
                                                          "rentCharges": 0,
                                                          "briefDescription": "",
                                                          "detailedDescription": "",
                                                          "accomodationType": "co-housing",
                                                          "audienceInterns": false,
                                                          "audienceStudents": false,
                                                          "audienceGirlsOnly": false,
                                                          "audienceBoysOnly": false,
                                                          "audienceNonSmokers": false,
                                                          "audiencePets": false,
                                                          "audienceCouples": false,
                                                          "audienceDomiciliation": false,
                                                          "wifi": false,
                                                          "kitchen": false,
                                                          "microwave": false,
                                                          "stove": false,
                                                          "fridge": false,
                                                          "oven": false,
                                                          "bedlinnen": false,
                                                          "bathroom": false,
                                                          "shower": false,
                                                          "laundry": false,
                                                          "dryer": false,
                                                          "vacuumCleaner": false,
                                                          "ironingBoard": false,
                                                          "bikeStorage": false,
                                                          "address": {
                                                            "street": "",
                                                            "streetNumber": "",
                                                            "districtId": ""
                                                          },
                                                          "photos": [
                                                          ],  
                                                          "active": true,
                                                          "verified": false
                                                        },
                                                        "briefConversationList" : {
                                                            ...response
                                                        }
                                                }

                                                this.setState(state, () => {console.log(this.state);this.setMessage("Advertisement deleted successfully")})
                                    }
                               }).catch(error => this.setMessage("Error at logonSuccess / showAdvertisement / getBriefConversations"))

                            

                    }
                }).catch(error => this.setMessage("Error at deleteAdd"))
    }
    
    deleteProfileMail(userID) {
        
            deleteProfileMail(userID).then(response => {
                
                if (!response) {
                        this.setMessage("Failed to send delete profile e-mail") 
                    } else {
                       
                        this.setMessage("To complete deleting your profile, please check your e-mail (see in the spam folder if you cannot find it)")
                                
                    }
            }).catch(error => this.setMessage("Error at deleteProfileMail"))
        
    }
    
    
    showPreferences() {
        
        
        
        loadPreferences().then(response => {
                       
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
            
                                let state = {
                                    ...this.state,
                                    emailMessageReceived: response.emailMessageReceived,
                                    emailAddApproved: response.emailAddApproved,
                                    emailAddExpired: response.emailAddExpired            
                                }

                                this.setState(state, () => {console.log(this.state);this.setMode("preferences");})
                                
                        
                    }
                }).catch(error => this.setMessage("Error at loadPreferences"))
        
        
        
    }
    
    showModifyProfile() {
        this.setMode("modifyProfile");
    }
    
    showModifyUser() {
        this.setMode("modifyUser");
    }
    
    modifyProfile(firstname, lastname, phone) {
        
        let user = {
            ...this.state.user,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            password:""
        }
        
        modifyProfile(user).then(response => {
                       
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
                        let state = {
                                    ...this.state,
                                    user:response          
                                }

                        this.setState(state, () => {console.log(this.state);this.setMessage("Profile saved");})
                    }
        }).catch(error => this.setMessage("Error at modifyProfile"))
        
    }
    
    modifyUser(userName) {
        
        modifyUser(userName).then(response => {
                    
                    if (response) {
                        this.setMessage("Please complete modifying your profile via the e-mail sent to "+userName+". (Verify your spam folder if you cannot find it)");
                    } else {
                        this.setMessage(response.message) 
                    }
        }).catch(error => this.setMessage("Error at modifyProfile"))
        
    }
    
    
    viewAdd(advertisementId) {
       
            
            //alert("showing add")
        
        
            loadAdd(advertisementId, this.state.language).then(response => {
                    
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
                               
                                
                        
                                let state = {
                                    ...this.state,
                                    "advertisement" : {
                                        ...response
                                    },
                                    "selectedCity": response.address.cityId,
                                    "selectedCountry": response.address.countryId,
                                    "selectedDistricts": [[response.address.districtId]]                                    
                                }
                                
                                
                                this.setState(state, () => {console.log(this.state);this.setMode("viewAdd");})
                                
                        }
                    }).catch(error => console.log(error))
        
        
    }
    
    showAddList(user,searchMode,rowStart) {//TO DO: remove optional reload parameter ?
        
        var rowBatchCount = 0 // searchMap, resultsOnly
        
        if (searchMode == "searchMap") {// {Unmount <MapSearchContainer>  so it will completely remount (for renewal of markers)
            let state = {
                    ...this.state,
                    "searchMode": "loading"
            }
            
            this.setState(state)
        
        } else if (searchMode == "searchAndResults" || searchMode == "resultsOnly") {
           
            rowBatchCount = this.state.rowBatchCount 
            
            /*if (rowStart == 0) {
                rowStart = this.state.rowStart
            } else*/ if (rowStart == -1) {
                rowStart = 0
            }
        }

        let districts = this.state.selectedDistricts
                
        var active = null
        var verified  = null
        var future = null
        
        /*if (user == null) {
            user = this.state.searchUser
        }*/
        
        
        //alert("districts: "+JSON.stringify(districts))
        if (user == null || user == 0)  {
            active = true
            verified = true
            future = true
            user = null
        } else if (user == -1) {
            user = null
            verified = false
            future = false
        } else {
            future = false
        }
        
        //alert("loadAddList user="+user+", active="+active+", verified="+verified+",future="+future+",rowStart="+rowStart+",rowBatchCount="+rowBatchCount)
                
        loadAddList(user,active,verified,future,districts,this.state.language,rowStart,rowBatchCount).then(response => {
                      
                    if (user == null || user == 0)  {
                        user = 0
                    }
            
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
                                let state = {
                                    ...this.state,
                                    searchUser: user,
                                    "searchMode": searchMode,
                                    "addList" : {
                                    ...response
                                    },
                                    rowStart:response.rowStart,
                                    rowTotal:response.rowTotal
                                }

                                this.setState(state, () => {console.log(this.state);})
                                
                    }
                }).then(() => {this.setMode("showAddList")}) 
                .catch(error => this.setMessage("Error at showAddList / loadAddList"))
        
        
       
    }
    
    
    
    updateAdvertisementSlot(advertisementId,active,verified) {
        
        
        updateAdvertisementSlot(advertisementId,active,verified).then(response => {
                       
                    if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
            
                                let state = {
                                    ...this.state,
                                    "advertisement" : {
                                        ...this.state.advertisement,
                                        "active":response.active,
                                        "verified":response.verified
                                    }
                                }

                                this.setState(state, () => {console.log(this.state);this.setMode("viewAdd");})
                    }
            
            }).catch(error => this.setMessage("Error at updateAdvertisementSlot"))
    }
    
    
    sendAdvertisementMessage(advertisementID,message) {
        
        
        sendAdvertisementMessage(advertisementID,message).then(response => {
                       
                    if (!response) {
                        this.setMessage("Failed to send message") 
                    } else {
            
                                this.setMessage("Message sent") 
                    }
            
            }).catch(error => this.setMessage("Error at sendAdvertisementMessage"))
    }
    
    loadCountries() {
        loadCountries(this.state.language).then(response=> {
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "countryList" : response.countryList 
                }

                this.setState(state, () => {console.log(this.state);}) 
            }
        }).catch(error => this.setMessage("Error at loadCountries"))
        
    }
    
    loadCities(countryId) {
        
        loadCities(countryId, this.state.language).then(response=> {
            
            
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "cityList" : response.cityList 
                }

                this.setState(state, () => {console.log(this.state);}) 
            }
        }).catch(error => this.setMessage("Error at loadCities"))
        
    }
    
    loadDistricts(cityId) {
        loadDistricts(cityId, this.state.language).then(response=> {
            
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "districtList" : response.districtList 
                }

                this.setState(state, () => {console.log(this.state);}) 
            }
        }).catch(error => this.setMessage("Error at loadDistricts"))
        
    }
    
    loadDistrictsAndPush(cityId, searchUser) {
        
        
        
        loadDistricts(cityId, this.state.language).then(response=> {
            
            
            
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "districtList" : response.districtList 
                }

                this.setState(state, () => {console.log(this.state);this.pushDistricts(searchUser)}) 
            }
        }).catch(error => this.setMessage("Error at loadDistrictsAndPush / loadDistricts"))
        
    }
    
    getCredits() {
        getCredits().then(response=> {
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "credits" : response.credits 
                }

                this.setState(state, () => {console.log(this.state);}) 
            }
        }).catch(error => this.setMessage("Error at getCredits"))
    }
    
    buyCredits(credits) {
        buyCredits(credits).then(response=> {
            if (!response.success) {
                        this.setMessage(response.message) 
            } else {
               let state = {
                        ...this.state,
                        "credits" : response.credits 
                }

                this.setState(state, () => {console.log(this.state);this.setMode("confirmCredits")}) 
            }
        }).catch(error => this.setMessage("Error at buyCredits"))
        
    }
    
    showConversation(conversationId, advertisementId, advertisementTitle) {
        
        if (conversationId < 0) {
           
            loadConversationByAddId(advertisementId).then(response=> {
                if (!response.success) {
                        this.setMessage(response.message) 
                } else {
                   let state = {
                            ...this.state,
                            "conversation" : {
                                ...response,
                                advertisementId:advertisementId,
                                advertisementTitle:advertisementTitle
                            }
                    }

                    this.setState(state, () => {console.log(this.state);this.setMode("showConversation")}) 
                }
            }).catch(error => this.setMessage("Error at showConversation / loadConversationByAddId"))   
        } else {
            loadConversationById(conversationId).then(response=> {
                if (!response.success) {
                        this.setMessage(response.message) 
                } else {
                   let state = {
                            ...this.state,
                            "conversation" : response
                    }

                    this.setState(state, () => {console.log(this.state);this.setMode("showConversation")}) 
                }
            }).catch(error => this.setMessage("Error at showConversation / loadConversationById"))   
            
        }
        
    }
    
    showConversationByAddId() {
        
        this.showConversation(-1, this.state.advertisement.advertisementId, this.state.advertisement.briefDescription)
        
    }
    
    showConversationById(conversationId) {
        
        this.showConversation(conversationId, -1)
        
    }
    
    addMessage(message) {
                    let state = {
                            ...this.state,
                            "conversation" : {
                                ...this.state.conversation,
                                messages:[[message]]
                            }
                    }

                    this.setState(state, () => {console.log(this.state);this.sendMessage()}) 
    }
    
    sendMessage() {
        sendMessage(this.state.conversation).then(response=> {
                
                if (response.success) {
                   let state = {
                            ...this.state,
                            "conversation" : response
                    }

                    this.setState(state, () => {console.log(this.state);this.setMode("showConversation")}) 
                } else if (response.message == "No credits") {
                    
                    let state = {
                            ...this.state,
                            "conversation":{
                                    conversationId:-1,
                                    advertisementId:-1,
                                    advertisementTitle:"",
                                    messages:[]
                                }
                    }

                    this.setState(state, () => {console.log(this.state);this.setMessage("To start a new conversation, you need to buy Staydler credits first.")}) 
                } else {
                    this.setMessage(response.message)
                }
            }).catch(error => this.setMessage("Error at sendMessage"))  
    }
    
    unsubscribeMail(conversationToken) {
        
            unsubscribeMail(conversationToken).then(response => {
                
                if (!response.success) {
                        this.setMessage(response.message) 
                    } else {
                       
                                if (response.success) {
                                    
                                    this.setMessage("You have succesfully unsubscribed from Staydler e-mails.")
                                }
                        
                    }
            }).catch(error => this.setMessage("Error at unsubscribeMail"))
        
    }
    
    
    deleteProfile(conversationToken) {
        
            if (confirm("Last warning: Are you certain you wish to delete the profile ? Deleting the profile will also remove all advertisements and conversations related to this profile. This action cannot be undone.") == true) {
                
                    deleteProfile(conversationToken).then(response => {

                        if (!response.success) {
                                this.setMessage(response.message) 
                            } else {

                                        let state = {
                                        user: {
                                            success: false,
                                            id: -1,
                                            authority: "",
                                            firstname: "",
                                            lastname: "",
                                            username: "",
                                            enabled: false,
                                            phone:""

                                        },
                                        selectedCountry:1,
                                        selectedCity:1,
                                        selectedCityLatitude:0,
                                        selectedCityLongitude:0,
                                        displayUrl:"https://www.staydler.com/IStay_Project/istayPhotos/Home.jpg",
                                        selectedDistricts:[[10]],
                                        countryList:[],
                                        cityList:[],
                                        districtList: [],            
                                        language: "en",
                                        emailMessageReceived: false,
                                        emailAddApproved: false,
                                        emailAddExpired: false,            
                                        mode: "welcome",
                                        searchMode: "",
                                        message: "",
                                        warning: "",    
                                        "advertisement": {
                                          "advertisementId": -1,
                                          "minimumMonths": 0,
                                          "dateAvailableFrom": "29-09-2019",
                                          "dateAvailableTo": "29-12-2022",
                                          "surfaceArea": 0,
                                          "rentFee": 0,
                                          "rentCharges": 0,
                                          "briefDescription": "",
                                          "detailedDescription": "",
                                          "accomodationType": "co-housing",
                                          "audienceInterns": false,
                                          "audienceStudents": false,
                                          "audienceGirlsOnly": false,
                                          "audienceBoysOnly": false,
                                          "audienceNonSmokers": false,
                                          "audiencePets": false,
                                          "audienceCouples": false,
                                          "audienceDomiciliation": false,
                                          "wifi": false,
                                          "kitchen": false,
                                          "microwave": false,
                                          "stove": false,
                                          "fridge": false,
                                          "oven": false,
                                          "bedlinnen": false,
                                          "bathroom": false,
                                          "shower": false,
                                          "laundry": false,
                                          "dryer": false,
                                          "vacuumCleaner": false,
                                          "ironingBoard": false,
                                          "bikeStorage": false,
                                          "address": {
                                            "street": "",
                                            "streetNumber": "",
                                            "districtId": ""
                                          },
                                          "photos": [
                                          ],  
                                          "active": true,
                                          "verified": false
                                        },
                                        addList:{
                                            addList:[{"success":false}]
                                        },
                                        conversation:{
                                            conversationId:-1,
                                            advertisementId:-1,
                                            advertisementTitle:"",
                                            messages:[]
                                        },
                                        briefConversationList: {
                                            success: false,
                                            conversations: [[]]
                                        },
                                        credits:0

                                    }

                                this.setState(state, () => {console.log(this.state);this.setMessage("You have succesfully removed your Staydler profile.");});
                                    
                            
                                
                        
                    }
            }).catch(error => this.setMessage("Error at deleteProfile"))
                
        }
        
    }
    
    confirmModifyUser(metaID, userName) {
        
        confirmModifyUser(metaID, userName).then(response => {
                
                if (response) {
                    
                        let state = {
                            ...this.state,
                            user: {
                                ...this.state.user,
                                username:userName
                            }
                        }
                    
                        this.setState(state, () => {console.log(this.state);this.setMessage("Username changed. Please log on with the new username.");});
                        
                    } else {   
                        this.setMessage(response.message)             
                    }
            }).catch(error => this.setMessage("Error at confirmModifyUser"))
        
    }
    
    
    componentDidMount() {
        const querystring=window.location.search;
        const urlParams=new URLSearchParams(querystring);
        const initScreen=urlParams.get('initScreen');
        const action=urlParams.get('action');
        const metaID=urlParams.get('metaID');
        const userName=urlParams.get('userName');
       
        if (initScreen=="logon") {
            
            this.setMode("logon")
            
        } else if (action=="unsubscribeMail" && metaID!=null) {
                   
               this.unsubscribeMail(metaID)                 
                   
        } else if (action=="deleteProfile" && metaID!=null) {
                   
               this.deleteProfile(metaID)                 
                   
        } else if (action=="confirmModifyUser" && metaID!=null) {
                   
               this.confirmModifyUser(metaID, userName)               
                   
        } else  if (action=="showAdvertisement" && metaID!=null) {
        
                this.viewAdd(metaID)
        } else {        
         
                this.showAddList(null, "searchAndResults", -1)
        
        }
    }
    
    
    render() {
        const querystring=window.location.search;
        const urlParams=new URLSearchParams(querystring);
        const confirmation=urlParams.get('confirmation');
        const resetToken=urlParams.get('resettoken');
        
        return (
           
                <div id="main" className="clearfix">
                        <div className = "flexBox" >
                            <div className = "cellLeft">
                            </div>
                            <div className = "cellMiddle">
                            <div id="logo">
                            <img src="./images/LogoStaydler.jpg" onClick={e => this.showAddList(null, "searchAndResults", -1)} alt="Home" />
                            </div>
                            {/* <Language languageSelected={this.state.language} selectLanguage={this.selectLanguage} /> */}
                        <Menu selectLogon={this.selectLogon} setMode={this.setMode} user={this.state.user} logoff={this.logoff} showPreferences={this.showPreferences} showModifyProfile={this.showModifyProfile} showModifyUser={this.showModifyUser} showAddList={this.showAddList} />
                        <Display displayUrl={this.state.displayUrl} mode={this.state.mode} user={this.state.user} />
                        
                        <Tiles setMode={this.setMode} user={this.state.user} mode={this.state.mode} showAddList={this.showAddList} createAdvertisement={this.createAdvertisement} />
                            
                            
                        <Logon message={this.state.message} warning={this.state.warning} setMode={this.setMode} setMessage={this.setMessage} setWarning={this.setWarning} logonSuccess={this.logonSuccess} selectLanguage={this.selectLanguage}  mode={this.state.mode} showAddList={this.showAddList} createAdvertisement={this.createAdvertisement} confirmation={confirmation != null ? confirmation : "NA"} resetToken={resetToken} emailMessageReceived={this.state.emailMessageReceived} emailAddApproved={this.state.emailAddApproved} emailAddExpired={this.state.emailAddExpired} onChangePreference={this.onChangePreference} user={this.state.user} deleteProfileMail={this.deleteProfileMail} modifyProfile={this.modifyProfile} modifyUser={this.modifyUser}/>
                        
                             
                            
                            {(this.state.mode == "updateAdd" || this.state.mode == "viewAdd" || this.state.mode == "explainAdd") ?
                        <Advertisement advertisement={this.state.advertisement} user={this.state.user} mode={this.state.mode} setMode={this.setMode} countUploadedPhotos={this.countUploadedPhotos} onChangeText={this.onChangeText} onChangeAddress={this.onChangeAddress} onChangeCheckbox={this.onChangeCheckbox} postAdvertisement={this.postAdvertisement} updateAdvertisementSlot={this.updateAdvertisementSlot} deletePhoto={this.deletePhoto} countryList={this.state.countryList} cityList={this.state.cityList} districtList={this.state.districtList} selectedCountry={this.state.selectedCountry} selectedCity={this.state.selectedCity} selectedDistrict={this.state.selectedDistricts[0][0]} changeCountry={this.changeCountry} changeCity={this.changeCity} changeDistrict={this.changeDistrict} 
                            showConversationByAddId={this.showConversationByAddId} deleteAdd={this.deleteAdd} sendAdvertisementMessage={this.sendAdvertisementMessage} />
                        : ""}
                        {(this.state.mode == "showAddList") ?
                        <AdvertisementList addList={this.state.addList.addList} selectedCityLatitude={this.state.selectedCityLatitude} selectedCityLongitude={this.state.selectedCityLongitude} searchUser={this.state.searchUser} rowStart={this.state.rowStart} rowBatchCount={this.state.rowBatchCount} rowTotal={this.state.rowTotal} viewAdd={this.viewAdd} searchMode={this.state.searchMode} showAddList={this.showAddList} countryList={this.state.countryList} cityList={this.state.cityList} selectedCountry={this.state.selectedCountry} selectedCity={this.state.selectedCity} changeCountry={this.changeCountry} changeSearchCity={this.changeSearchCity} pushDistricts={this.pushDistricts} />
                        : ""}
                        
                        {(this.state.mode == "showConversation" || this.state.mode == "messages" || this.state.mode == "explainConversation") ?
                            <Conversation mode={this.state.mode} user={this.state.user} conversation={this.state.conversation} viewAdd={this.viewAdd} addMessage={this.addMessage}  briefConversationList={this.state.briefConversationList} showConversationById={this.showConversationById}/>
                        : ""}
                        {(this.state.mode == "credits" || this.state.mode == "confirmCredits") ?
                            <Checkout mode={this.state.mode} credits={this.state.credits}  buyCredits={this.buyCredits}/>
                        : ""}
                            
                            </div>
                            <div className = "cellRight">
                            </div>
                        </div>

                        <div className = "flexBox" >
                            <div className = "cellLeft">
                            </div>
                            <div className = "cellMiddleNoBorder">
                                <div className = "flexBox">
                                    <div className = "cellSpan2 center">
                                         <a href="mailto:contact@Staydler.com">Contact Staydler</a>
                                    </div> 
                                </div>
                            </div>
                            <div className = "cellRight">
                            </div>
                        </div>

                    </div>
           
        )
        

    }

}

export default Main


                        
