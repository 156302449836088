import {
    Component
} from 'react';
import PropTypes from 'prop-types'

class Language extends Component {

    constructor(props) {
        super(props)
        this.selectLanguage = this.selectLanguage.bind(this)
        
        this.state = {
            
        }
    }
    
    selectLanguage(e, lang) {
        e.preventDefault()
        this.props.selectLanguage(lang)
        
    }


    render() {
        const {languageSelected} = this.props
        return (
            
            <div id="language">
                <span className={languageSelected == "en" ? "langBox langSelected" : "langBox"} onClick={e => this.selectLanguage(e, 'en')}>en</span>
                <span className={languageSelected == "fr" ? "langBox langSelected" : "langBox"} onClick={e => this.selectLanguage(e, 'fr')}>fr</span>
                <span className={languageSelected == "de" ? "langBox langSelected" : "langBox"} onClick={e => this.selectLanguage(e, 'de')}>de</span>
            </div> 
        )
    }

}


Language.propTypes = {
    languageSelected: PropTypes.string,
    selectLanguage: PropTypes.func
}

/*** handle the case where no property "selectLanguage" is passed ***/
Language.defaultProps = {
    selectLanguage: f=>f
}

export default Language
