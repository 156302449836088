import fetch from 'isomorphic-fetch'
import {hostLive, hostLocal, mode, GOOGLE_API_KEY} from '../config'


const parseResponse = response => response.json()

var urlPrefix = (mode == "Live") ? hostLive.protocol+hostLive.url/*+":"+hostLive.port*/ : hostLocal.protocol+hostLocal.url+":"+hostLocal.port

const APICall = (url, options) => {
    
    return new Promise((resolve, reject) => {
        fetch (
            url,
            options    
        ).then(parseResponse)
        .then(data => resolve(data))
        .catch(error => {console.error(error);reject(error);})    
    })
}


export const validateAddress = (state) => {
    
    var url = "https://addressvalidation.googleapis.com/v1:validateAddress?key="+GOOGLE_API_KEY
    
    var address = {
        "address": {
            "regionCode":state.countryCode,
            "locality":state.district,
            "postalCode":state.zipCode,
            "addressLines": [state.street+" "+state.streetNo]
        }
    }
    
    console.log(JSON.stringify(address))
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        body: JSON.stringify(address)
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}




export const logon = (userName, passWord) => {
    
    var url = urlPrefix+"/IStay_Project/api/authenticate?username="+userName+"&password="+passWord
    
    var options = {
        //CORS 
        //mode:  'cors',
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        }/*,credentials: 'include'*/
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const fetchUser = () => {
    
    var url = urlPrefix+"/IStay_Project/api/private/user"
    
    //CORS
    var token = sessionStorage.getItem("token")

    
    var options = {
        //CORS
        //mode:  'cors',
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization':token
        }/*,credentials: 'include'*/
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const logoff = () => {
    
    var url = urlPrefix+"/IStay_Project/api/private/logoff" //TO DO: api/logoff ?
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}



export const unsubscribeMail = (confirmationToken) => {
    
        
    var url = urlPrefix+"/IStay_Project/api/private/unsubscribe/"+confirmationToken;
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}


export const deleteProfile = (confirmationToken) => {
    
        
    var url = urlPrefix+"/IStay_Project/api/private/deleteProfile/"+confirmationToken;
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}



export const getCredits = () => {
    
    var url = urlPrefix+"/IStay_Project/api/private/credits"
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const buyCredits = (credits) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/buyCredits?credits="+credits
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}





export const register = (userName, passWord, firstName, lastName, phoneNumber, authority) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/user/"
    
        
    var user = {
        username: userName,
        password: passWord,
        lastname: lastName,
        firstname: firstName,
        authority: authority,
        phone: phoneNumber
    };
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        /*credentials: 'include',*/
        body: JSON.stringify(user)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const langPreference = (acceptLanguage) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/langPreference"
    
    var preference = {
        acceptLanguage: acceptLanguage,
        emailMessageReceived: true,
        emailAddExpired: true
    }
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(preference)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const loadPreferences = () => {
    
    var url = urlPrefix+"/IStay_Project/api/private/mailPreference"
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}


export const setPreferences = (emailMessageReceived, emailAddApproved, emailAddExpired) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/mailPreference"
    
    var preference = {
        acceptLanguage: "NA",
        emailAddApproved: emailAddApproved,
        emailMessageReceived: emailMessageReceived,
        emailAddExpired: emailAddExpired
    }
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(preference)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const startReset = (userName) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/passwordreset?username="+userName;
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        }/*,credentials: 'include'*/
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const processReset = (resetToken, passWord) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/password/"+resetToken+"/?password="+passWord;
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        }/*,credentials: 'include'*/
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const createAdvertisementSlot = () => {
    
    var url = urlPrefix+"/IStay_Project/api/private/advertisementslot"
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const updateAdvertisementSlot = (advertisementId, active, verified) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/advertisementslot/update"
    
    var advertisementSlot = {
        advertisementId:advertisementId,
        active:active,
        verified:verified
    };
    
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(advertisementSlot)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const modifyProfile = (user) => {
    
    console.log(JSON.stringify(user))
    
    var url = urlPrefix+"/IStay_Project/api/private/modifyProfile"
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(user)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const confirmModifyUser = (metaID, userName) => {
    

    var url = urlPrefix+"/IStay_Project/api/public/confirmModifyUser/"+metaID+"/?userName="+userName
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        }/*,credentials: 'include'*/
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}



export const modifyUser = (userName) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/modifyUser/?userName="+userName
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}

export const sendAdvertisementMessage = (advertisementID, message) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/advertisementMessage?advertisementID="+advertisementID+"&message="+message
    
   
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}


export const postAdd = (advertisement, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/advertisement"
    
    /*var adverisement = {
        ...advertisment
    };*/
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        },
        credentials: 'include',
        body: JSON.stringify(advertisement)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}


export const updateAdd = (advertisement, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/advertisement/update"
    
    /*var adverisement = {
        ...advertisment
    };*/
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        },
        credentials: 'include',
        //body: JSON.stringify(advertisement)
        body: JSON.stringify(advertisement)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}

export const loadAdd = (advertisementId, language) => {
    
    
    
    var url = urlPrefix+"/IStay_Project/api/public/advertisement/"+advertisementId+"/"
    
    /*var adverisement = {
        ...advertisment
    };*/
    
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        }/*,credentials: 'include'*/
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}


export const deleteAdd = (advertisementID) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/deleteAdvertisement?advertisementID="+advertisementID

    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}

export const deleteProfileMail = (userID) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/deleteProfileMail?userID="+userID

    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}


export const loadAddList = (user,active,verified,future,districts,language,rowStart,rowBatchCount) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/advertisement/list/"
    
    if (!Array.isArray(districts)) {
        districts = null
    }
    
    var constraints = {
        user: user,
        active: active,
        verified: verified,
        future: future,
        districts:districts,
        rowStart:rowStart,
        rowBatchCount:rowBatchCount
    };
    
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        },
        /*credentials: 'include',*/
        body: JSON.stringify(constraints)
        
    }
    
    return new Promise(function(resolve, reject) {
                            
        APICall(url, options).then(data => resolve(data))
            .catch(error => reject(error))  
    }
                      
    )
}





export const loadCountries = (language) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/countryList/"
    
    
        
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        }/*,credentials: 'include'*/
        /*,
        body: JSON.stringify(constraints)*/
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}



export const loadCities = (countryId, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/cityList/?countryId="+countryId
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        }/*,credentials: 'include'*/
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}

export const loadDistricts = (cityId, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/public/districtList/?cityId="+cityId
    
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Accept-Language':language
        }/*,credentials: 'include'*/
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}





export const uploadPhotos = (advertisementId, formData, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/upload/"+advertisementId+"/"
    
    
    var options = {
        method: 'POST',
        headers: {
            'Accept-Language':language
        },
        credentials: 'include',
        body: formData
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
    
}

export const removePhoto = (advertisementId, photoURI, language) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/deletePhoto/"+advertisementId+"/?photoURI="+photoURI
    
    
    var options = {
        method: 'POST',
        headers: {
            'Accept-Language':language
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
    
}

export const loadConversationByAddId = (advertisementID) => {
    
    
    var url = urlPrefix+"/IStay_Project/api/private/conversationByAddId/?advertisementID="+advertisementID
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}


export const loadConversationById = (conversationId) => {
    
    var url = urlPrefix+"/IStay_Project/api/private/conversationById/"
    
    var conversation = {
        conversationId: conversationId
    };
    
    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(conversation)
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}


export const sendMessage = (conversation) => {
    
    
    var url = urlPrefix+"/IStay_Project/api/private/message/"
    

    var options = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(conversation)
        
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    }
                      
    )
}

export const getBriefConversations = () => {
    
    
    var url = urlPrefix+"/IStay_Project/api/private/briefConversation/"
    
    var options = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },credentials: 'include'
    }
    
    return new Promise(function(resolve, reject) {
        APICall(url, options).then(data => resolve(data))
                            .catch(error => reject(error))
    })
}





