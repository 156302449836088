import React from 'react';
import {GOOGLE_API_KEY} from '../config'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';


const MapContainer = ({address}) => {
  
  const {isLoaded,loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY
  });
    
  const mapStyles = {        
    height: "350px",
    width: "100%"
  };
  
  const defaultCenter = {
    lat: address.latitude,
    lng: address.longitude
  }
  
  const markerLocation = {
      name: address.street+" "+address.streetNumber,
      location: { 
        lat: address.latitude,
        lng: address.longitude 
      },
    }
  const renderMap = () => {
  return (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
        >
            <Marker key={markerLocation.name} position={markerLocation.location}/>
        </GoogleMap>
  )
  }
    
  if (loadError) {
    alert("error")
    return <div>Map cannot be loaded right now, sorry.</div>;
  } else {     
    return isLoaded ? renderMap() : null;
  }    
}

export default MapContainer;