// set mode to "Local" when running on localhost, set mode to "Live" when running on Microsoft Azure
export const mode = "Live" 

let compile = false

// configuration to run on localhost
export const hostLocal = {
    protocol: "http://",
    url: "localhost",
    port: 8080
}

// configuration to run on eApps
export const hostLive = {
    protocol: "https://",
    url: "www.staydler.com",
    //url: "69.89.13.141",
    port: 80
}

export const CLIENT_ID = process.env.CLIENT_ID || "AWdjv8wnDB1rIg9nuweeNayIKE7aGdPFJ98aKlX1KZc2JaVaY0pvpyq7V8P_KSp2jtwdxthTsoBT1gRb"
export const APP_SECRET = process.env.APP_SECRET || "EJ_TQg8cVuSek4ribPF9sFfIBjJc4ccCsaOUwSqGafTvzb-enck6HlAUwg0WAMnQH8CYOApGl_7tZ4m2"

export const GOOGLE_API_KEY = "AIzaSyDAcPTB194dZZZa9jZPrIzNqi9HnjdbI0k"