import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import '../css/slides.css';
import 'react-slideshow-image/dist/styles.css';
import PropTypes from 'prop-types'


const slideWidth = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--slidewidth");
      
const slideHeight = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--slideheight");

var prevWidth = 0;
var prevHeight = 0;


class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.deletePhoto = this.deletePhoto.bind(this)
    this.scalePhoto = this.scalePhoto.bind(this)
    
    
    this.state = {
      photos: [
         
        ]
    };
  }

    
  deletePhoto(e, index) {
      e.preventDefault
      let photoURI = this.props.photos[index][0]
      
      this.props.deletePhoto(photoURI)
  }
    
  scalePhoto(e, imgID) {
      e.preventDefault
      var theImg = document.getElementById(imgID);
      
      //console.log("slide width/height: " + slideWidth+"/"+slideHeight);
      
      var maxWidth = Number(slideWidth)           // Max width for the image
      var maxHeight = Number(slideHeight)          // Max height for the image
      var ratio = 0                // Used for aspect ratio

      // Get current dimensions
      var width = theImg.naturalWidth;
      var height = theImg.naturalHeight; 
      //console.log("dimensions: " + width + "x" + height);

      // If the current width is larger than the max, scale height
      // to ratio of max width to current and then set width to max.
      if (width > maxWidth) {
          //console.log("Shrinking width (and scaling height)")
          ratio = maxWidth / width
          height = height * ratio
          width = maxWidth
          e.target.style.height=""+height+"px"
          e.target.style.width=""+width+"px"
          
         //console.log("new dimensions: " + width + "x" + height)
          
      }

      // If the current height is larger than the max, scale width
      // to ratio of max height to current and then set height to max.
      if (height > maxHeight) {
          //console.log("Shrinking height (and scaling width)")
          ratio = maxHeight / height
          width = width * ratio
          height = maxHeight
          e.target.style.height=""+height+"px"
          e.target.style.width=""+width+"px"
          
          //console.log("new dimensions: " + width + "x" + height)
          
      }
  }    

  render() {
    const {photos, hasDelete, briefDescription} = this.props 
    const properties = {
      duration: 5000,
      autoplay: true,
      transitionDuration: 500,
      arrows: true,
      infinite: true,
      easing: "ease"
    }
    

    
    return (
     
      
        
        <div className="slide-container">
          <Slide ref={this.slideRef} {...properties}>
            {photos?.map((each, index) => (
              <div key={index} className="each-slide">
                <img id={"IMG"+index} className="lazy" src={each[0]} alt="sample" style={{width: slideWidth, height : slideHeight}} onLoad={e => this.scalePhoto(e, "IMG"+index)} alt={"For rent: "+briefDescription} />
                {this.props.hasDelete ?
                    <div style={{ position: "absolute", top: "0px" }}><button onClick={e => this.deletePhoto(e, index)}>X</button></div>
                    : ""
                }
                
              </div>
            ))}
          </Slide>
        </div>

        
      
    );
  }
}

Slideshow.propTypes = {
    photos: PropTypes.array,
    briefDescription: PropTypes.string,
    hasDelete: PropTypes.bool,
    deletePhoto: PropTypes.func
}


Slideshow.defaultProps = {
   deletePhoto: f => f
}

export default Slideshow;