import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import {hostLive, hostLocal, mode as modeHost} from '../config'

var urlPrefix = (modeHost == "Live") ? hostLive.protocol+hostLive.url/*+":"+hostLive.port*/ : hostLocal.protocol+hostLocal.url+":"+hostLocal.port


class Tiles extends Component {

    constructor(props) {
        super(props)
        //this.selectLogon = this.selectLogon.bind(this)
        //this.selectAdvertisement = this.selectAdvertisement.bind(this)
        this.setMode = this.setMode.bind(this)
        this.showAddList = this.showAddList.bind(this)
        this.createAdvertisement = this.createAdvertisement.bind(this)
        this.buyCredits = this.buyCredits.bind(this)
        
        
        this.state = {
        }
    }

    
    
    setMode(e, mode) {
        e.preventDefault()
        this.props.setMode(mode)
        
    }
    
     
    createAdvertisement(e) {
        //e.preventDefault()
        this.props.createAdvertisement()
    }
    
        
    showAddList(e,user, searchMode, rowStart) {
        //e.preventDefault()        
        this.props.showAddList(user, searchMode, rowStart)
        
    }
    
    
    buyCredits(e) {
        this.setMode(e, "credits")
    }
    
    //TO DO: reset advertisement before "Create adverisement"
    render() {
        const {
            mode,
            user
        } = this.props 
       
        if (mode=="explainConversation") {
            return (
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                <a href="javascript:void(0);"  onClick={e => this.setMode(e, "subscribe")} >
                                <img src={urlPrefix+"/IStay_Project/images/tileSubscribe.jpg"} className="tileDisplayCellspan" /> 
                                <figcaption>Sign up</figcaption>
                                </a>
                            </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={e => this.setMode(e, "logon")} >
                                 <img src={urlPrefix+"/IStay_Project/images/user.jpg"} className="tileDisplay" /> 
                                 <figcaption>Log on</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        </div>
            )
        } else if (mode=="welcome" || mode=="welcomeLoggedOn" || mode=="viewAdd" || mode=="preferences" || mode=="modifyProfile" || mode=="modifyUser") {
            return (
                    
                <div>
                    
                    {user.id < 0 ?
                     <div>
                     <div className = "flexBoxNoWrap" >
                        <div className = "cellTileCellspan2">
                            <figure className="tile">
                             <a href="javascript:void(0);"  onClick={e => this.setMode(e, "subscribe")} >
                             <img src={urlPrefix+"/IStay_Project/images/tileSubscribe.jpg"} className="tileDisplayCellspan" /> 
                             <figcaption>Sign up</figcaption>
                             </a>
                            </figure>
                        </div>
                     </div>
                     
                     
                     <div className = "flexBoxNoWrap" >
                        <div className = "cellTileLeft50">
                            <figure className="tile">
                             <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                             <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                             <figcaption>Browse</figcaption>
                             </a>
                            </figure>
                        </div>
                        
                        <div className = "cellTileRight50">
                             <figure className="tile">
                             <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "subscribe")} >
                             <img src={urlPrefix+"/IStay_Project/images/tileCreateAdd.jpg"} className="tileDisplay" /> 
                             <figcaption>Create</figcaption>
                             </a>                        
                             </figure>
                        </div>
                    </div>
                    </div>
                        : ""
                        }
                        
                        {user.id > 0 && user.authority == "TENANT" ?
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);" onClick={e => this.createAdvertisement(e)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileCreateAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Create</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>
                                 </figure>
                            </div>
                         </div>
                        </div>
                         : ""
                        }

                        {user.id > 0 && user.authority == "LESSOR" ?
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>                                                         
                                 </figure>
                            </div>                             
                         </div>
                        </div>
                         : ""
                        }
                    </div>
                    
                )
        } else if (mode=="messages") {
            return (
                <div>
                    {user.id > 0 && user.authority == "TENANT" ?
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={e => this.createAdvertisement(e)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileCreateAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Create</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        </div>
                         : ""
                        }

                        {user.id > 0 && user.authority == "LESSOR" ?
                        <div>
                            <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>
                            </div>
                        </div>
                         : ""
                        }
                    </div>
            )
            
            
        } else if (mode=="updateAdd") {
                return (
                    <div>
                    {user.id > 0 && user.authority == "TENANT" ?
                    <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, user.username, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Listings</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>
                                 </figure>
                            </div>
                         </div>
                        </div>
                    : ""
                    }
                    {user.id > 0 && user.authority == "LESSOR" ?
                    <div>                         
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>                       
                                 </figure>
                            </div>                             
                         </div>
                        </div>
                    : ""
                    }
                    </div>
                )   
        }  else if (mode=="showAddList") {
                return (
                    
                <div>
                    
                    {user.id < 0 ?
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.setMode(e, "subscribe")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileSubscribe.jpg"} className="tileDisplayCellspan" /> 
                                 <figcaption>Sign up</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        : ""
                        }
                        
                        {user.id > 0 && user.authority == "TENANT" ?
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);" onClick={e => this.createAdvertisement(e)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileCreateAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Create</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, user.username, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Listings</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>
                                 </figure>
                            </div>
                         </div>
                        </div>
                         : ""
                        }

                        {user.id > 0 && user.authority == "LESSOR" ?
                        <div> 
                            <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>                       
                                 </figure>
                            </div>
                            </div>
                        </div>
                         : ""
                        }
                    </div>
                    
                )
   
        }  else if (mode=="showConversation") {
                return (
                    <div>
                       {user.id > 0 && user.authority == "TENANT" ?
                        <div>
                         <div className = "flexBoxNoWrap" >
                            <div className = "cellTileCellspan2">
                                <figure className="tile">
                                 <a href="javascript:void(0);" onClick={e => this.createAdvertisement(e)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileCreateAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Create</figcaption>
                                 </a>
                                </figure>
                            </div>
                         </div>
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, user.username, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Listings</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>
                                 </figure>
                            </div>
                         </div>
                        </div>
                         : ""
                        }

                        {user.id > 0 && user.authority == "LESSOR" ?
                        <div>                         
                        <div className = "flexBoxNoWrap" >
                            <div className = "cellTileLeft50">
                                <figure className="tile">
                                 <a href="javascript:void(0);"  onClick={e => this.showAddList(e, null, "searchAndResults", -1)} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileAdd.jpg"} className="tileDisplay" /> 
                                 <figcaption>Browse</figcaption>
                                 </a>
                                </figure>
                            </div>

                            <div className = "cellTileRight50">
                                 <figure className="tile">
                                 <a href="javascript:void(0);" onClick={(e) => this.setMode(e, "messages")} >
                                 <img src={urlPrefix+"/IStay_Project/images/tileMyMessage.jpg"} className="tileDisplay" /> 
                                 <figcaption>My Messages</figcaption>
                                 </a>                       
                                 </figure>
                            </div>                             
                         </div>
                        </div>
                         : ""
                        }
                    </div>
                )   
        } else {
            return (
                <div>&nbsp;</div>
            )
        }
           
        
    }

}

Tiles.propTypes = {
    mode: PropTypes.string,
    user: PropTypes.object,
    setMode: PropTypes.func,
    showAddList: PropTypes.func,
    createAdvertisement: PropTypes.func
}


Tiles.defaultProps = {
    setMode: f => f,
    showAddList: f => f    
}

export default Tiles
