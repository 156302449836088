import {
    Component
} from 'react';
import '../css/content.css';
import PropTypes from 'prop-types'
import '../css/slides.css';
import Slideshow from './Slideshow'
import {FacebookShareButton, FacebookIcon} from 'react-share';


class ShortAdvertisement extends Component {

   
    
    constructor(props) {
        super(props)
        this.viewAdd = this.viewAdd.bind(this)
        this.renderSlideShow = this.renderSlideShow.bind(this)
        
        this.state = {
        }
    }
    
    viewAdd(e, advertisementId) {
        e.preventDefault()        
        this.props.viewAdd(advertisementId)
        
    }
    
    renderSlideShow(add) {
        if (add.photos?.length > 0) {
            return (
                <Slideshow photos={add?.photos} briefDescription={add?.briefDescription} hasDelete={false}/>
            )
        }
        
        
        return null
    }
    
    
    render() {
        const {            
            add
        }  = this.props 
    
        console.log(add.address)
        
        return (
            
        <div>    
            <div  className="flexBox">
                <div className = "cellSpan2 formHeader"><b>{add.briefDescription}</b></div>
            </div>
            
            <div  className="flexBox">
                <div className = "cellLeft50 formLabel">Address:</div>
                <div className = "cellRight50 formLabel">{add.address?.street}<br/>
                {add.address?.district}<br/>
                {add.address?.city}<br/>
                {add.address?.country}
                </div>
            </div>
            
            <div  className="flexBox">
                <div className = "cellSpan2 formLabel">{add.accomodationType}</div>                
            </div>
            <div  className="flexBox">
                <div className = "cellSpan2 formLabel">Available from {add.dateAvailableFrom} (minimum {add.minimumMonths} months)</div>
            </div>
            <div  className="flexBox">
                <div className = "cellSpan2 formLabel">Surface: {add.surfaceArea} m²</div>
            </div>
            <div  className="flexBox">
                <div className = "cellSpan2 formLabel">Monthly fee: <b>{add.rentFee} €</b></div>
            </div>
            <div  className="flexBox">
                <div className = "cellSpan2 formLabel">Monthly charges: <b>{add.rentCharges} €</b></div>
            </div>
        
            <div  className="flexBox">
                <div className = "cellSpan2">{this.renderSlideShow(add)}</div>
            </div>
        
            <div className = "flexBox" >
                        <div className = "cellSpan2">
                            <FacebookShareButton
                                url={'https://www.staydler.com/index.html?action=showAdvertisement&metaID='+add?.advertisementId}
                                quote={'Have a look at this room for rent on Staydler.com'}
                                hashtag="#RentRoomsWithStaydler"
                              >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div> 
            </div>
        
        
            <div  className="flexBox">
                <div className = "cellSpan2"><a href="javascript:void(0);" onClick={e => this.viewAdd(e, add.advertisementId)}>Details</a> </div>
            </div>
        </div>
        )
    }
                                
                        
}

ShortAdvertisement.propTypes = {
    add: PropTypes.object,
    viewAdd: PropTypes.func
}


ShortAdvertisement.defaultProps = {    
    viewAdd: f => f
}

export default ShortAdvertisement
